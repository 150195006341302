import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Loading from "../../Shared/Components/Loading";
import Container from "../../Shared/Components/Container";
import BreakDiv from "../../Shared/Components/BreakDiv";
import Navbar from "../Components/Navbar";
import SelectSortList from "../Components/SelectSortList";
import Footer from "../Components/Footer";
import { PopupContext } from "../../Context/PopupContext";
import { PhotoContext } from "../../Context/PhotoContext";
import { fetchCategories, fetchPhotos } from "../../Api/Artphotos";
import PhotoItem from "../../Shared/Components/PhotoItem";
import CategoryItem from "../../Shared/Components/CategoryItem";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import PhotoItemSkeleton from "../../Shared/Components/Skeleton/PhotoItemSkeleton";
import CategorySkeleton from "../../Shared/Components/Skeleton/CategorySkeleton";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";
import CustomPagination from "../../Shared/Components/CustomPagination";

function ArtPhotos() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const {
    setDashItem,
    selectedSortValue,
    setSelectedSortValue,
    loading,
    lazyLoading,
    categoryLoading,
    setCategoryLoading,
    currentStatusId,
    setCurrentStatusId,
    categoryId,
    setCategoryId,
    totalPages,
    setTotalPages,
    itemPerPage,
    setItemPerPage,
    pageNumber,
    setPageNumber,
    paginationKey,
    setPaginationKey,
    logNavigation,
  } = useContext(PhotoContext);

  const { sortListOpen, setSortListOpen } = useContext(PopupContext);
  const selectSortRef = useOutsideClick(() => setSortListOpen(false));

  const [isLoadingPage, setIsLoadingPage] = useState(false);

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };

  const fetchCategoriesData = async () => {
    try {
      setCategoryLoading(true);
      const categoriesData = await fetchCategories();
      // console.log(categoriesData.data);
      setCategories(categoriesData.data);
      setCategoryLoading(false);
    } catch (error) {
      console.error("Error fetching categories data:", error);
    }
  };

  const fetchPhotosData = async () => {
    try {
      setIsLoadingPage(true);
      const photosData = await fetchPhotos(
        currentStatusId,
        null,
        categoryId,
        pageNumber
      );
      // console.log(photosData.data);
      setPhotos(photosData.data);
      setTotalPages(photosData.last_page);
      setItemPerPage(photosData.per_page);
      setPageNumber(photosData.current_page);
    } catch (error) {
      console.error("Error fetching photos data:", error);
    } finally {
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPhotosData();
  }, [currentStatusId, categoryId, pageNumber]);

  useEffect(() => {
    logNavigation("/art-photos");
    lazyLoading();
    setDashItem(false);
    fetchCategoriesData();
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <div className={`${theme === "dark" ? "bg-DarkMainBg" : "bg-white"}`}>
      <Navbar
        sticky={true}
        className={`${theme === "dark" ? "bg-DarkMainBg" : "bg-white"
          } -mb-5 max-lg:mb-0`}
      />
      <div className="mt-[50px]">
        <Container>
          <div
            style={{ direction: "ltr" }}
            className="flex justify-center pb-[7px] mb-2 artSlide relative max-h-[184px]"
          >
            <Swiper
              className="w-full flex justify-center"
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={0.2}
              breakpoints={{
                1280: {
                  slidesPerView: 4.2,
                },
                1024: {
                  slidesPerView: 3.2,
                },
                768: {
                  slidesPerView: 2.2,
                },
                0: {
                  slidesPerView: 2,
                },
              }}
              navigation
              pagination={{ clickable: true }}
            >
              {categoryLoading
                ? Array.from({ length: 4 }).map((_, index) => (
                  <SwiperSlide
                    className="max-md:w-[370px] max-md:flex max-md:justify-center max-md:items-center"
                    key={index}
                  >
                    <CategorySkeleton />
                  </SwiperSlide>
                ))
                : categories &&
                categories.map((item) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <CategoryItem
                        key={item.id}
                        id={item.id}
                        image={item.image}
                        name={item.name}
                        onClick={() => {
                          setCategoryId(item.id);
                          setCurrentStatusId(8);
                          setPageNumber(1);
                          setPaginationKey((prev) => prev + 1);
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          <div className="flex gap-2 items-center mx-auto my-4">
            {currentStatusId !== 8 && (
              <div
                ref={selectSortRef}
                onClick={() => setSortListOpen(!sortListOpen)}
                className={`${sortListOpen ? "rounded-[0px]" : "rounded-[20px]"
                  } ${theme === "dark"
                    ? "text-DarkMainText hover:bg-HoverPrimaryColor"
                    : "hover:bg-gray-100"
                  } cursor-pointer max-md:text-[14px] relative flex items-center gap-1 border-2 px-2 py-1 hover:shadow-lg duration-300`}
              >
                <h1>
                  {t("Sorted by")} {t(selectedSortValue)}
                </h1>
                <IoIosArrowDown />
                <SelectSortList
                  className={`${sortListOpen ? "opacity-100 z-50" : "opacity-0 -z-50"
                    } absolute left-[50%] -translate-x-[50%] -bottom-[160px] text-center w-full border-2 hover:shadow-lg duration-300`}
                />
              </div>
            )}

            {categoryId && (
              <h1
                onClick={() => {
                  setSelectedSortValue(t("Latest"));
                  setCurrentStatusId(2);
                  setCategoryId(null);
                  setPaginationKey((prev) => prev + 1);
                  setPageNumber(1);
                  setTotalPages(0);
                }}
                className={`${theme === "dark"
                    ? "text-DarkMainText hover:bg-HoverPrimaryColor"
                    : "hover:bg-gray-100"
                  } rounded-[20px] max-md:text-[14px] cursor-pointer relative flex items-center gap-1 border-2 px-2 py-1 hover:shadow-lg duration-300`}
              >
                {t("Show All Photos")}
              </h1>
            )}
          </div>
          {isLoadingPage ? (
            <div
              style={{ direction: "ltr" }}
              className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full"
            >
              {Array.from({ length: 25 }).map((_, index) => (
                <PhotoItemSkeleton key={index} />
              ))}
            </div>
          ) : photos && photos.length > 0 ? (
            <div className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full">
              {photos.map((product, index) => {
                return (
                  <PhotoItem
                    link={`${product.id}`}
                    key={index}
                    image={product?.url_picture[0]?.url_image}
                    imageHover={product?.url_picture[1]?.url_image}
                    title={product.name}
                    category={product.category}
                    description={product.description}
                    price={product.price}
                    rate_value={product.rate_value}
                    discount={product.price_after_discount}
                    quentity={product.quentity}
                  />
                );
              })}
            </div>
          ) : (
            <div className="text-2xl h-[700px] w-full flex justify-center items-center">
              <h1>Sorry, No photos available</h1>
            </div>
          )}
          {photos.length > 0 && (
            <div className="mt-5">
              <CustomPagination
                key={paginationKey}
                totalPages={totalPages}
                currentPage={pageNumber}
                itemPerPage={itemPerPage}
                handlePageClick={handlePageClick}
              />
            </div>
          )}
          <BreakDiv className="my-8" />
          <Footer />
        </Container>
      </div>
    </div>
  );
}

export default ArtPhotos;
