import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdAccessTime } from "react-icons/md";
import { IoGift, IoPerson } from "react-icons/io5";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "../../Shared/Components/Popup";
import UpdateOrderStatus from "../Components/UpdateOrderStatus";
import { fetchOrderInfo } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import OrderInformationSkeleton from "../Components/Skeleton/OrderInformationSkeleton";
import { formatDateData } from "../../utils/dateUtils";

function OrderInformation() {
  const currency = window.localStorage.getItem("currency") || "EUR";
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { id } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [refreshKey, setRefreshKey] = useState(Date.now());
  const { loading, setLoading } = useContext(PhotoContext);
  const { updateOrderStatusOpen, setUpdateOrderStatusOpen } =
    useContext(PopupContext);

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const response = await fetchOrderInfo(id);
      // console.log(response.data);
      setOrderDetails(response.data);
      setCurrentStatus(response.data.status);
      setCartItems(response.data.cart_items);
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      setLoading(false);
    }
  };
  // const format = (date) => {
  //   date
  //     ? new Date(date).toLocaleString("en-GB", {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         second: "2-digit",
  //         hour12: false,
  //       })
  //     : "";
  //   return date;
  // };
  useEffect(() => {
    fetchOrderDetails();
  }, [refreshKey]);
  return (
    <div className="p-10">
      {loading ? (
        <OrderInformationSkeleton />
      ) : cartItems && cartItems.length > 0 ? (
        <div>
          <div className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"}`}>
            <div className="flex items-center gap-2 text-[20px] p-4 mb-5">
              <IoPerson />
              <h1 className="font-semibold">
                {orderDetails?.cart?.user?.name}
              </h1>
            </div>
          </div>
          <div className="flex justify-between items-center mb-5">
            <div className="flex items-center gap-3">
              <FaArrowLeftLong
                className="text-PrimaryColor cursor-pointer"
                onClick={() => navigate("/dashboard/orders")}
              />
              <h1 className="text-[20px] font-semibold">Full Order Details</h1>
            </div>
            <div className="flex items-center gap-2">
              <div
                onClick={() => setUpdateOrderStatusOpen(true)}
                className="text-[18px] text-white bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 cursor-pointer py-1 px-4 rounded-xl"
              >
                Change Status
              </div>
              <div className="flex items-center gap-1 font-semibold text-[18px] py-1 px-4 bg-white rounded-xl">
                <h1 className="text-[#777]">Status : </h1>
                <h2 className="text-PrimaryColor">{orderDetails?.status}</h2>
              </div>
            </div>
          </div>
          <div
            className={`${
              theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
            } flex flex-col bg-white rounded-xl mb-5`}
          >
            <div className="flex justify-between items-center border-b border-[#ebe7e7] p-4">
              <div className="flex items-center gap-1">
                <h1 className="text-[18px] font-semibold">Order ID : </h1>
                <h2 className="text-[18px] text-[#777]">
                  #{orderDetails?.number}
                </h2>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <MdAccessTime size={20} className="text-PrimaryColor" />
                  <div className="flex items-center gap-1">
                    <h1 className="font-semibold">Time : </h1>
                    <h2 className="text-[#777]">
                      <h2 className="text-[#777]">
                        {formatDateData(
                          orderDetails?.created_at,
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </h2>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-nowrap justify-between p-4">
              <div className="flex flex-col">
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">
                    Earliest Delivery Date :
                  </h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.minimum_delivery_date}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">
                    Latest Delivery Date :
                  </h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.max_delivery_date}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Address : </h1>
                  <h2 className="text-[#777]">
                    {`${orderDetails?.addresses?.country?.name}, ${
                      orderDetails?.addresses?.region?.name
                    } ${
                      orderDetails?.addresses?.city !== "0"
                        ? `, ${orderDetails?.addresses?.city}`
                        : ""
                    }
                    ${
                      orderDetails?.addresses?.address_line_1 !== "0"
                        ? `, ${orderDetails?.addresses?.address_line_1}`
                        : ""
                    }
                    ${
                      orderDetails?.addresses?.address_line_2 !== "0"
                        ? `, ${orderDetails?.addresses?.address_line_2}`
                        : ""
                    }
                    `}
                  </h2>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Email : </h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.addresses?.email}
                  </h2>
                </div>
                <div className="flex items-center gap-1 py-1">
                  <h1 className="font-semibold max-w-full">Total Price : </h1>
                  <h2 className="text-[#777]">
                    {orderDetails?.total_price} {currency}
                  </h2>
                </div>
                {orderDetails.price_after_discount !== 0 && (
                  <div className="flex items-center gap-1 py-1">
                    <h1 className="font-semibold max-w-full">
                      Discount Price :{" "}
                    </h1>
                    <h2 className="text-[#777]">
                      {orderDetails?.discount_price} {currency}
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`flex flex-col`}>
            <div
              className={`${
                theme === "dark" && "border-DarkMainText"
              } border rounded-xl`}
            >
              <table
                className={`${
                  theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                } w-full table-auto rounded-xl overflow-hidden`}
              >
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Items</th>
                    <th className="border px-4 py-2">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.cart_items?.map((item) => (
                    <tr key={item.id} className="relative">
                      <td className="relative border text-center px-4 py-2 w-[500px] max-md:w-[150px]">
                        <div className="w-[150px] h-[150px] mx-auto max-md:w-[100px] max-md:h-[100px]">
                          <img
                            className="w-full h-full object-cover"
                            src={item.url_photo}
                            alt=""
                          />
                        </div>
                      </td>
                      <td
                        className={`${
                          theme === "dark"
                            ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                            : "max-md:bg-white max-md:border-MainText/20"
                        } border text-center`}
                      >
                        <table
                          className={`${
                            theme === "dark"
                              ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                              : "max-md:bg-white max-md:border-MainText/20"
                          } border min-w-full table-auto`}
                        >
                          <tr>
                            <td
                              className={`${
                                theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                              } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border font-semibold`}
                            >
                              Material
                            </td>
                            <td
                              className={`${
                                theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                              } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border`}
                            >
                              <div className="min-h-full">
                                <div className="w-[100px] h-[100px] mx-auto max-md:max-w-[50px] max-md:max-h-[50px]">
                                  <img src={item.material.image} alt="" />
                                </div>
                                <div className="mt-2">{item.material.name}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className={`${
                                theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                              } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border font-semibold`}
                            >
                              Size
                            </td>
                            <td
                              className={`${
                                theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                              } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border`}
                            >
                              <div className="min-h-full">
                                <div>{`${item.size.length_inch}x${item.size.width_inch}"`}</div>
                                <div className="mt-2">{`${item.size.length_cm}x${item.size.width_cm}cm`}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className={`${
                                theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                              } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border font-semibold`}
                            >
                              Price
                            </td>
                            <td
                              className={`${
                                theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                              } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border`}
                            >
                              <div>
                                {parseFloat(item.price).toFixed(2)} {currency}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td className="font-semibold text-PrimaryColor text-end px-4 md:py-2 max-md:pt-6">
                      {orderDetails.price_after_discount !== 0
                        ? "Total Before Discount"
                        : "Total"}
                    </td>
                    <td
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText/70"
                          : "text-[#777]"
                      } text-center px-4 md:py-2 max-md:pt-6 max-md:text-start`}
                    >
                      {orderDetails?.total_price?.toFixed(2)} {currency}
                    </td>
                  </tr>
                  {orderDetails.price_after_discount !== 0 && (
                    <tr>
                      <td className="font-semibold text-PrimaryColor text-end px-4 md:py-2 max-md:pt-6">
                        Total :
                      </td>
                      <td className="text-center px-4 md:py-2 max-md:pt-6 max-md:text-start">
                        {orderDetails?.price_after_discount?.toFixed(2)}{" "}
                        {currency}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {orderDetails.gift_note !== "0" && (
              <div className="py-4 px-1 md:mb-5 overflow-hidden max-w-full">
                <div className="flex flex-col gap-1 text-[18px] max-md:text-[16px]">
                  <div className="flex items-center gap-2">
                    <IoGift className="text-PrimaryColor" />
                    <h1 className="font-semibold">The special note :</h1>
                  </div>
                </div>{" "}
                <div className="text-[18px] max-md:text-[16px]">
                  {orderDetails.gift_note}
                </div>
              </div>
            )}
          </div>
          {updateOrderStatusOpen && (
            <Popup
              toggleSwitch={updateOrderStatusOpen}
              children={
                <UpdateOrderStatus
                  id={id}
                  currentStatus={currentStatus}
                  refreshOrderInfo={() => setRefreshKey(Date.now())}
                  setOrderDetails={setOrderDetails}
                />
              }
              overLayClassName="z-40"
              className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
              onClose={() => setUpdateOrderStatusOpen(false)}
            />
          )}
        </div>
      ) : (
        <div>No Data</div>
      )}
    </div>
  );
}

export default OrderInformation;
