import React, { useContext, useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import Navbar from "../Components/Navbar";
import { PhotoContext } from "../../Context/PhotoContext";
import Loading from "../../Shared/Components/Loading";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { IoGift, IoCall } from "react-icons/io5";
import Container from "../../Shared/Components/Container";
import { useTheme } from "../../Context/ThemeContext";
import { fetchOrderDetailsForUser } from "../../Api/Paymentdetails";
import MakeDecision from "../../Shared/Components/MakeDecision";
import Popup from "../../Shared/Components/Popup";
import { PopupContext } from "../../Context/PopupContext";
import MainButton from "../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import { MdEmail } from "react-icons/md";
import { FaLocationPin } from "react-icons/fa6";
import { useParams } from "react-router-dom";

function PaymentDetails() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { loading, setLoading, setSelectedItemId, setSelectedOrderId } =
    useContext(PhotoContext);
  const { makeDecisionOpen, setMakeDecisionOpen } = useContext(PopupContext);
  const [orderDetails, setOrderDetails] = useState([]);
  const [count, setCount] = useState(0);

  const { orderNumber } = useParams();
  const currency = window.localStorage.getItem("currency") || "EUR";

  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const ordersData = await fetchOrderDetailsForUser(orderNumber);
      // console.log('ordersData',ordersData);
      setOrderDetails(ordersData);
      setLoading(false);
    } catch (err) {
      console.error("fetch all orders err", err);
    }
  };
  useEffect(() => {
    fetchOrdersData();
  }, [count]);

  return (
    <div className={`${theme === "dark" && "bg-DarkMainBg"}`}>
      <Navbar sticky={true} hideBasket={true} />
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <div className="px-10 max-md:px-1 pb-10 mt-7 min-h-[1200px]">
            <h1 className="text-[20px] font-semibold mb-5">
              {t("Payment Details")}
            </h1>
            {orderDetails?.addresses?.contact_number !== "0" && (
              <div
                className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                  } md:flex md:items-center md:gap-2 rounded-xl mb-5`}
              >
                <div className="text-[18px] max-md:text-[16px] py-4 px-1">
                  <IoCall className="text-PrimaryColor inline-block" />{" "}
                  <h1 className="font-semibold inline-block">
                    {orderDetails?.addresses?.contact_number}
                  </h1>
                </div>
              </div>
            )}
            <div
              className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                } md:flex md:items-center md:gap-2 rounded-xl mb-5`}
            >
              <div className="text-[18px] max-md:text-[16px] py-4 px-1">
                <MdEmail className="text-PrimaryColor inline-block" />{" "}
                <h1 className="font-semibold inline-block">
                  {orderDetails?.addresses?.email}
                </h1>
              </div>
            </div>
            <div
              className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                } md:flex md:items-center md:gap-2 rounded-xl mb-5`}
            >
              <div className="text-[18px] max-md:text-[16px] py-4 px-1">
                <FaLocationPin className="text-PrimaryColor inline-block" />{" "}
                <h1 className="font-semibold inline-block">
                  {orderDetails?.addresses?.country?.name},{" "}
                  {orderDetails?.addresses?.region?.name}
                  {orderDetails?.addresses?.city !== "0" &&
                    " ," + orderDetails?.addresses?.city}{" "}
                  {orderDetails?.addresses?.address_line_1 !== "0" &&
                    "," + orderDetails?.addresses?.address_line_1}
                </h1>
              </div>
            </div>
            {orderDetails && (
              <div>
                {orderDetails.length > 1 && <BreakDiv className="my-[20px]" />}
                <div
                  className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                    } flex flex-col rounded-xl mb-5 relative`}
                >
                  <div className="flex justify-between items-center border-b border-[#ebe7e7] py-4 px-1">
                    <div className="flex items-center gap-1">
                      <h1 className="text-[18px] font-semibold max-md:text-[16px]">
                        {t("Order ID")} :
                      </h1>
                      <h2
                        className={`${theme === "dark"
                            ? "text-DarkMainText/70"
                            : "text-[#777]"
                          } text-[18px] max-md:text-[16px]`}
                      >
                        #{orderDetails?.number}
                      </h2>
                    </div>
                    <div className="flex items-center gap-4">
                      <div
                        className={`${theme === "dark"
                            ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                            : "max-md:bg-white max-md:border-MainText/20"
                          } flex items-center gap-2 text-[18px] max-md:text-[16px] max-md:absolute max-md:top-[40px] max-md:left-[50%] max-md:-translate-x-[50%] max-md:py-[2px] max-md:px-4 max-md:border-[2px] max-md:rounded-2xl`}
                      >
                        <h1
                          className={`${theme === "dark"
                              ? "text-DarkMainText/70"
                              : "text-[#777]"
                            }`}
                        >
                          {t("Status")}:
                        </h1>
                        <h2 className="text-PrimaryColor">
                          {orderDetails?.status}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-nowrap justify-between py-4 px-1">
                    <div className="flex flex-col">
                      <div className="flex md:items-center gap-1 py-1 text-[18px] max-md:text-[16px] max-md:flex-col">
                        <h1 className="font-semibold max-w-full">
                          {t("Earliest Delivery Date")}:
                        </h1>
                        <h2
                          className={`${theme === "dark"
                              ? "text-DarkMainText/70"
                              : "text-[#777]"
                            }`}
                        >
                          {orderDetails?.minimum_delivery_date}
                        </h2>
                      </div>
                      <div className="flex md:items-center gap-1 py-1 text-[18px] max-md:text-[16px] max-md:flex-col">
                        <h1 className="font-semibold max-w-full">
                          {t("Latest Delivery Date")}:
                        </h1>
                        <h2
                          className={`${theme === "dark"
                              ? "text-DarkMainText/70"
                              : "text-[#777]"
                            }`}
                        >
                          {orderDetails?.max_delivery_date}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex flex-col`}>
                  <div
                    className={`${theme === "dark" && "border-DarkMainText"
                      } border rounded-xl`}
                  >
                    <table
                      className={`${theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
                        } w-full table-auto rounded-xl overflow-hidden`}
                    >
                      <thead>
                        <tr>
                          <th className={`border px-4 py-2`}>{t("Items")}</th>
                          <th className={`border px-4 py-2`}>{t("Details")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails?.cart_items?.map((item) => (
                          <tr key={item.id} className="relative">
                            <td className="relative border text-center px-4 py-2 w-[500px] max-md:w-[150px]">
                              <TiDelete
                                onClick={() => {
                                  setMakeDecisionOpen(true);
                                  setSelectedItemId(item.id);
                                  setSelectedOrderId(item.order_id);
                                }}
                                className="text-PrimaryColor hover:text-HoverPrimaryColor absolute top-0 left-0 cursor-pointer"
                                size={30}
                              />
                              <div className="w-[150px] h-[150px] mx-auto max-md:w-[100px] max-md:h-[100px]">
                                <img
                                  className="w-full h-full object-cover"
                                  src={item.url_photo}
                                  alt=""
                                />
                              </div>
                            </td>
                            <td
                              className={`${theme === "dark"
                                  ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                  : "max-md:bg-white max-md:border-MainText/20"
                                } border text-center`}
                            >
                              <table
                                className={`${theme === "dark"
                                    ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                    : "max-md:bg-white max-md:border-MainText/20"
                                  } border min-w-full table-auto`}
                              >
                                <tr>
                                  <td
                                    className={`${theme === "dark"
                                        ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                        : "max-md:bg-white max-md:border-MainText/20"
                                      } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border font-semibold`}
                                  >
                                    {t("Material")}
                                  </td>
                                  <td
                                    className={`${theme === "dark"
                                        ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                        : "max-md:bg-white max-md:border-MainText/20"
                                      } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border`}
                                  >
                                    <div className="min-h-full">
                                      <div className="w-[100px] h-[100px] mx-auto max-md:max-w-[50px] max-md:max-h-[50px]">
                                        <img src={item.material.image} alt="" />
                                      </div>
                                      <div className="mt-2">
                                        {item.material.name}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className={`${theme === "dark"
                                        ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                        : "max-md:bg-white max-md:border-MainText/20"
                                      } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border font-semibold`}
                                  >
                                    {t("Size")}
                                  </td>
                                  <td
                                    className={`${theme === "dark"
                                        ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                        : "max-md:bg-white max-md:border-MainText/20"
                                      } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border`}
                                  >
                                    <div className="min-h-full">
                                      <div>{`${item.size.length_inch}x${item.size.width_inch}"`}</div>
                                      <div className="mt-2">{`${item.size.length_cm}x${item.size.width_cm}cm`}</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className={`${theme === "dark"
                                        ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                        : "max-md:bg-white max-md:border-MainText/20"
                                      } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border font-semibold`}
                                  >
                                    {t("Price")}
                                  </td>
                                  <td
                                    className={`${theme === "dark"
                                        ? "max-md:bg-DarkMainBg max-md:border-DarkMainText/70"
                                        : "max-md:bg-white max-md:border-MainText/20"
                                      } px-4 py-2 max-md:px-2 max-md:py-1 max-md:text-[14px] border`}
                                  >
                                    <div>
                                      {parseFloat(item.price).toFixed(2)}{" "}
                                      {currency}
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        ))}
                        <Popup
                          toggleSwitch={makeDecisionOpen}
                          children={
                            <MakeDecision
                              action={t("remove")}
                              type={t("cart item")}
                              refreshPaymentDetails={setCount}
                            />
                          }
                          overLayClassName="z-40"
                          className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
                          onClose={() => setMakeDecisionOpen(false)}
                        />
                        <tr>
                          <td className="font-semibold text-PrimaryColor text-end px-4 md:py-2 max-md:pt-6">
                            {orderDetails.price_after_discount !== 0
                              ? t("Total Before Discount")
                              : t("Total")}
                          </td>
                          <td
                            className={`${theme === "dark"
                                ? "text-DarkMainText/70"
                                : "text-[#777]"
                              } text-center px-4 md:py-2 max-md:pt-6 max-md:text-start`}
                          >
                            {orderDetails?.total_price?.toFixed(2)} {currency}
                          </td>
                        </tr>
                        {orderDetails.price_after_discount !== 0 && (
                          <tr>
                            <td className="font-semibold text-PrimaryColor text-end px-4 md:py-2 max-md:pt-6">
                              {t("Total")}:
                            </td>
                            <td className="text-center px-4 md:py-2 max-md:pt-6 max-md:text-start">
                              {orderDetails?.price_after_discount?.toFixed(2)}{" "}
                              {currency}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {orderDetails.gift_note !== "0" && (
                    <div className="py-4 px-1 md:mb-5 overflow-hidden max-w-full">
                      <div className="flex flex-col gap-1 text-[18px] max-md:text-[16px]">
                        <div className="flex items-center gap-2">
                          <IoGift className="text-PrimaryColor" />
                          <h1 className="font-semibold">
                            {t("The special note")} :
                          </h1>
                        </div>
                      </div>{" "}
                      <div className="text-[18px] max-md:text-[16px]">
                        {orderDetails.gift_note}
                      </div>
                    </div>
                  )}

                  <MainButton
                    link={`/payment-form/${orderNumber}`}
                    content={t("Pay Now")}
                    className="min-w-[165px] h-[36px] rounded-[6px] font-[700] px-2 mt-3 mb-[24px]"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    </div>
  );
}

export default PaymentDetails;
