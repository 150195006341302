import React from "react";
import Skeleton from "react-loading-skeleton";

function CategorySkeleton() {
  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="w-[80px] max-md:w-[60px] h-[80px] max-md:h-[60px]">
        <Skeleton className="w-full h-full" circle />
      </div>
      <Skeleton width={"75px"} className="h-[15px] rounded-xl" />
    </div>
  );
}

export default CategorySkeleton;
