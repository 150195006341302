import React from "react";
import { useTranslation } from "react-i18next";
import FeaturesItem from "../../../Components/FeaturesItem";
import nails from "../../../../assets/imgs/Features/nails.png";
import free from "../../../../assets/imgs/Features/free.png";
import refund from "../../../../assets/imgs/Features/refund.png";

function Features() {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-3 gap-10 max-md:grid-cols-1 py-6 mb-[40px]">
      <FeaturesItem
        image={nails}
        title={t("No nails needed!")}
        description={t("Our frames stick to any type of wall.")}
      />
      <FeaturesItem
        image={free}
        title={t("Free worldwide shipping!")}
        description={t("Delivered to your door in just 7 days.")}
      />
      <FeaturesItem
        image={refund}
        title={t("Your satisfaction is guaranteed.")}
        description={t(
          "Not satisfied? Get a full refund within 30 days, no questions asked."
        )}
      />
    </div>
  );
}

export default Features;
