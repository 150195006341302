import { PhotoContext } from "../../../Context/PhotoContext";
import BreakDiv from "../../../Shared/Components/BreakDiv";
import Container from "../../../Shared/Components/Container";
import Loading from "../../../Shared/Components/Loading";
import Features from "./Sections/Features";
import Landing from "./Sections/Landing";
import Reviews from "./Sections/ReviewsSection";
import StickyFrame from "./Sections/StickyFrame";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { useContext, useEffect, useRef } from "react";
import { useTheme } from "../../../Context/ThemeContext";
import ScrollTopButton from "../../Components/ScrollTopButton";
import {
  apiClient,
  getCurrencyByCountry,
  whoVisitWebsite,
} from "../../../Api/api";
import PrivacySettings from "../../Components/PrivacySettings";
import LandingGallery from "./Sections/LandingGallery";
import SelectLanguage from "../../Components/SelectLanguage";
import MainButton from "../../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import ReviewsSection from "./Sections/ReviewsSection";

function Home() {
  const { theme } = useTheme();
  const userId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const { loading, lazyLoading, totalPhotos, logNavigation } =
    useContext(PhotoContext);
  const vividVisions = async () => {
    try {
      const response = await whoVisitWebsite();
    } catch (error) {
      console.error("vividVisions err : ", error);
    }
  };
  const privacyShowed = localStorage.getItem("privacyShowed");

  const fetchCurrency = async () => {
    try {
      const currency = await getCurrencyByCountry();
      // console.log(currency.data);
      localStorage.setItem("currency", currency.data || "EUR");
    } catch (err) {
      console.error("get currency err : ", err);
    }
  };

  const videoRef = useRef();

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const playPromise = videoElement.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Playback started successfully
          })
          .catch((error) => {
            // Handle the error, e.g., log it or show a message to the user
            console.error("Video playback failed:", error);
          });
      }
    }
  }, []);
  useEffect(() => {
    logNavigation("/home");
    lazyLoading();
    !privacyShowed && vividVisions();
    fetchCurrency();
    window.pintrk && window.pintrk('track', 'homePagevisit', {
      event_id: "eventId0005",
      em: userId,
    });
  }, []);
  return (
    <div className="flex flex-col justify-center items-center">
      {loading ? (
        <Loading />
      ) : (
        <div
          className={`${theme === "dark"
            ? "text-DarkMainText bg-DarkMainBg"
            : "text-MainText"
            } z-0 w-full`}
        >
          <Navbar sticky={loading ? false : true} />
          <Container>
            <Landing />
            <BreakDiv className="my-[40px] max-md:hidden" />
            <Features />
            <BreakDiv className="my-[40px] max-md:hidden" />
            <StickyFrame />
            <BreakDiv className="my-[40px] max-md:hidden" />
            
            {/* <div className="flex justify-between max-md:flex-col max-md:gap-3 max-md:mt-[40px] max-md:text-center">
              <div className="flex flex-col my-auto gap-5">
                <div className="text-[36px] font-[600] leading-[50px] max-md:hidden">
                  {t("Hanging Photos")},
                  <br />
                  {t("Reinvented")}
                </div>
                <div className="flex flex-col gap-3 max-md:gap-2">
                  <div className="md:pl-5 md:border-l-2 md:border-l-PrimaryColor text-[26px] max-md:text-[24px] leading-[36px] font-[500]">
                    {t("No hammers and nails")}
                  </div>
                  <div className="md:pl-5 md:border-l-2 md:border-l-PrimaryColor text-[26px] max-md:text-[24px] leading-[36px] font-[500]">
                    {t("Adjust to perfection")}
                  </div>
                  <div className="md:pl-5 md:border-l-2 md:border-l-PrimaryColor text-[26px] max-md:text-[24px] leading-[36px] font-[500]">
                    {t("Leaves no marks")}
                  </div>
                </div>
              </div>
              <div className="aspect-video shadow-photoItemShadow md:w-[600px]">
                <video
                  ref={videoRef}
                  className="w-full h-full rounded-[6px] object-cover"
                  loop
                  muted
                  playsInline
                  src={vid}
                ></video>
              </div>
            </div> */}
            <LandingGallery />
            <BreakDiv className="my-[40px] max-md:hidden" />
            <ReviewsSection homeMode={true} />
            <BreakDiv className="my-[40px] max-md:hidden" />
            <Footer />
            <ScrollTopButton />
          </Container>
          <div
            className={`${theme === "dark" ? "bg-DarkMainBg" : "bg-white"
              } sticky left-0 bottom-0 z-0 w-full mx-auto py-[12px] max-md: md:hidden`}
          >
            <MainButton
              link={"/edit-photos"}
              content={
                totalPhotos && totalPhotos > 0
                  ? `${t("Continue Design")} (${totalPhotos})`
                  : t("Start Design")
              }
              className="w-[95%] m-auto flex justify-center items-center min-w-[209px] h-[52px] text-[18px] rounded-[10px]"
            />
          </div>
        </div>
      )}
      {!privacyShowed && <PrivacySettings />}
      <SelectLanguage />
    </div>
  );
}

export default Home;
