import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../Context/ThemeContext";
import MainButton from "../../../../Shared/Components/MainButton";
import img from "../../../../assets/imgs/ArtHome/imgMain.jpg";
import imgMob from "../../../../assets/imgs/ArtHome/mainMob.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/controller";
import "swiper/css/effect-fade";
import { PhotoContext } from "../../../../Context/PhotoContext";

function LandingArt() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { logNavigation } = useContext(PhotoContext);
  const pictures = [

    img,
    "https://backend.tecrek.com/public/Photos/l.jpg",
    "https://backend.tecrek.com/public/Photos/a.jpg",
    "https://backend.tecrek.com/public/Photos/c.jpg",
    "https://backend.tecrek.com/public/Photos/u.jpg",
    "https://backend.tecrek.com/public/Photos/m.jpg",
    "https://backend.tecrek.com/public/Photos/v.jpg",
    "https://backend.tecrek.com/public/Photos/h.jpg",
  ];

  const picturesMobile = [
    imgMob,
    "https://backend.tecrek.com/public/Photos/18.jpg",
    "https://backend.tecrek.com/public/Photos/1100.7.jpg",
    "https://backend.tecrek.com/public/Photos/5.2.5.jpg",
  ];

  return (
    <div className={`${theme === "dark" && "text-DarkMainText"} mb-[20px]`}>
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        loop
        autoplay
        effect="fade"
        speed={500}
      >
        {pictures.map((pic, index) => (
          <SwiperSlide key={index}>
            <div className="flex justify-center items-center relative min-w-full aspect-video max-h-[800px] bg-black max-md:hidden">
              <div className="flex flex-col items-center gap-5 z-10 text-white">
                <div className="flex flex-col items-center gap-2">
                  <div className="text-[14px]">
                    {t("Award-Winning Gallery Art")}
                  </div>
                  <div className="text-[30px] leading-[1.2] tracking-[3px]">
                    {t("Art For All Seasons, Style For Every Reason.")}
                  </div>
                </div>
                <MainButton
                  onClick={() => logNavigation("/art-photos")}
                  link={`/art-photos`}
                  content={t("SHOP NOW")}
                  className={`w-fit mx-auto font-[700] py-[14px] px-[28px]`}
                />
              </div>
              <img
                className="w-full h-full object-cover absolute left-0 top-0 opacity-70"
                src={pic}
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex flex-col items-center gap-5 max-md:mt-[10px] md:hidden shadow-xl">
        <div className="w-full overflow-hidden">
          <Swiper
            modules={[Autoplay, EffectFade]}
            slidesPerView={1}
            loop
            autoplay
            effect="fade"
            speed={500}
          >
            {picturesMobile.map((picture, index) => (
              <SwiperSlide key={index}>
                <div className="max-h-[600px] overflow-hidden">
                  <img
                    className="w-full h-full object-cover"
                    src={picture}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex flex-col items-center gap-5 text-center">
          <div className="flex flex-col items-center gap-2">
            <div className="text-[14px]">{t("Award-Winning Gallery Art")}</div>
            <div className="text-[22px] leading-[1.2] tracking-[3px]">
              {t("Art For All Seasons, Style For Every Reason.")}
            </div>
          </div>
          <MainButton
            onClick={() => logNavigation("/art-photos")}
            link={`/art-photos`}
            content={t("SHOP NOW")}
            className={`w-fit mx-auto text-[14px] font-[700] py-[14px] px-[28px] mb-5`}
          />
        </div>
      </div>
    </div>
  );
}

export default LandingArt;
