import React from "react";
import Skeleton from "react-loading-skeleton";

function PhotoItemSkeleton({ className }) {
  return (
    <div className={`${className} flex flex-col w-full max-sm:mx-auto`}>
      <div className="w-full basis-[80%] rounded-xl">
        <Skeleton width={"100%"} className="h-[250px] max-md:h-[200px]" />
      </div>
      <div className={`w-full basis-[20%]`}>
        <div className="mx-auto h-[20px] my-1 rounded-xl text-center">
          <Skeleton width={"70%"} className="h-full" />
        </div>
        <div className="mx-auto h-[20px] my-1 rounded-xl text-center">
          <Skeleton width={"85%"} className="h-full" />
        </div>
        <div className="mx-auto h-[20px] my-1 rounded-xl text-center">
          <Skeleton width={"30%"} className="h-full" />
        </div>
        <div className="mx-auto h-[20px] my-1 rounded-xl text-center">
          <Skeleton width={"25%"} className="h-full" />
        </div>
      </div>
    </div>
  );
}

export default PhotoItemSkeleton;
