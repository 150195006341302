import React from "react";
import MainButton from "../../Shared/Components/MainButton";
import pic from "../../assets/imgs/Thanks/thanks.png";
import picCover from "../../assets/imgs/Thanks/13.04.jpg";
import { useTheme } from "../../Context/ThemeContext";
import { useTranslation } from "react-i18next";

function Thanks() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const orderNumber = localStorage.getItem("orderNumber");
  return (
    <div
      className={`${
        theme === "dark" ? "bg-DarkMainBg" : "bg-MainBg"
      } min-h-screen flex justify-center items-center`}
    >
      <div className="flex items-center gap-[50px]">
        <div className="flex flex-col gap-5">
          <div className="max-w-[450px] max-h-[300px]">
            <img className="w-full h-full object-cover" src={pic} alt="" />
          </div>
          <div className="flex items-center gap-2 max-w-[450px] max-md:px-2">
            <div className="rounded-[5px] basis-1/2">
              <MainButton
                className={`w-full h-full px-4 py-2`}
                content={t(`Continue shop`)}
                link={`/art-home`}
              />
            </div>
            <div className="rounded-[5px] basis-1/2">
              <MainButton
                className={`w-full h-full px-4 py-2`}
                content={t(`Track your order`)}
                link={`/order-details/${orderNumber}`}
              />
            </div>
          </div>
        </div>
        <div className="rounded-tl-full w-[450px] h-[700px] overflow-hidden max-md:hidden">
          <img className="w-full h-full object-cover" src={picCover} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Thanks;
