import React from "react";
import Skeleton from "react-loading-skeleton";

function AddressInputSkeleton() {
  return (
    <div>
      <Skeleton count={1} className="mb-[0.25rem] h-[24px]" width={100} />
      <Skeleton count={1} className="mb-[20px] w-full h-[50.56px]" />
    </div>
  );
}

export default AddressInputSkeleton;
