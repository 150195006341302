import { React, useState, useContext } from "react";
import { PopupContext } from "../../Context/PopupContext";
import {
  deleteCategory,
  deletePhotoItem,
  deleteSize,
  switchUserActive,
} from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import { deleteItemFromOrder } from "../../Api/Paymentdetails";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toastLoading, toastSuccess } from "./ToastNotification/Toast";
import { deletePhotoFromCart } from "../../Api/Editphotos";
import { toast } from "react-toastify";
import { PhotoContext } from "../../Context/PhotoContext";

function MakeDecision({
  dashboard,
  action,
  type,
  userId,
  itemId,
  orderId,
  refreshPaymentDetails,
  refreshUsers,
  refreshCategories,
  refreshPictures,
  setUsers,
  refreshSizes,
  photos,
  setPhotos,
  categories,
  setCategories,
  setSizes,
}) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const { setMakeDecisionOpen } = useContext(PopupContext);
  const { selectedItemId, selectedOrderId } = useContext(PhotoContext);
  const navigate = useNavigate();
  const handleConfirm = async () => {
    setSpinnerLoad(true);
    try {
      if (type === "cart item") {
        const response = await deleteItemFromOrder(
          selectedItemId,
          selectedOrderId
        );
        // console.log(response.data);
        if (response.data.total_price === 0) {
          navigate("/");
        } else {
          refreshPaymentDetails((prev) => prev + 1);
        }
      } else if ((type = "photo item")) {
        const toastId = toastLoading(t("Deleting photo..."));
        const response = await deletePhotoFromCart(itemId); // Assume this function resolves when the deletion is successful
        // console.log("Deletion response:", response);
        toast.dismiss(toastId);
        if (response && response.success) {
          toastSuccess(t("Photo deleted successfully!"));
          navigate("/edit-photos", { replace: true });
          window.location.reload(); // Force the browser to reload the page
        } else {
          throw new Error("Failed to delete photo"); // Throw an error if the API response is not successful
        }
      } else if (type === "photo") {
        setPhotos([]);
        await deletePhotoItem(itemId).then(() => {
          setPhotos(photos.filter((photo) => photo.id !== itemId));
        });

        refreshPictures();
      } else if (type === "category") {
        setCategories([]);
        await deleteCategory(itemId).then(() =>
          setCategories(categories.filter((category) => category.id !== itemId))
        );

        refreshCategories();
      } else if (type === "size") {
        await deleteSize(itemId).then(() =>
          setSizes((prevSizes) =>
            prevSizes.filter((size) => size.id !== itemId)
          )
        );

        refreshSizes();
      } else {
        setUsers([]);
        await switchUserActive(userId);

        refreshUsers();
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      setSpinnerLoad(false);
      setMakeDecisionOpen(false);
    }
  };
  return (
    <div
      className={`${theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
        } rounded-[20px] max-md:rounded-none max-h-[673px] max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full flex flex-col gap-2 p-5`}
    >
      <h1>
        {dashboard ? "Are you sure you want to" : t("Are you sure you want to")}{" "}
        {dashboard ? action : t(action)} {dashboard ? "this" : t("this")}{" "}
        {dashboard ? type : t(type)} ?
      </h1>
      <div className="flex items-center justify-start my-3 gap-3">
        <button
          onClick={() => setMakeDecisionOpen(false)}
          className="px-2 py-1 cursor-pointer rounded-[62px] border font-bold  border-[#e4e4e4] text-PrimaryColor  bg-white hover:bg-gray-200 min-w-[70px] duration-300 flex justify-center items-center"
        >
          {dashboard ? "Cancel" : t("Cancel")}
        </button>
        <button
          onClick={handleConfirm}
          className={`${spinnerLoad
              ? "pointer-events-none cursor-default opacity-75"
              : "cursor-pointer"
            } px-2 py-1 rounded-[62px] border font-bold  border-orange-300 text-white bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 min-w-[70px] h-[33.28px] flex justify-center items-center`}
        >
          {spinnerLoad ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : dashboard ? (
            "Ok"
          ) : (
            t("Ok")
          )}
        </button>
      </div>
    </div>
  );
}

export default MakeDecision;
