import React, { createContext, useContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import placeholder from "../../src/assets/imgs/placeholder.png";
import {
  fetchEffects,
  fetchMaterials,
  fetchMats,
  fetchSizes,
} from "../Api/Editphotos";
import Cookies from "js-cookie";
import i18n from "../translations/i18n";
import { useTranslation } from "react-i18next";
import { PopupContext } from "./PopupContext";
import { logout } from "../Api/Auth";
import { toastSuccess } from "../Shared/Components/ToastNotification/Toast";
import { curiousToken, postUrlPage, userToken } from "../Api/api";

export const PhotoContext = createContext();

export const PhotoProvider = ({ children }) => {
  const { setSideBarOpen } = useContext(PopupContext);
  const userEmail = window.localStorage.getItem("email");
  const handleImageError = (e) => {
    e.target.src = placeholder;
  };
  const lazyLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [materials, setMaterials] = useState([]);
  const [materialsLoading, setMaterialsLoading] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [sizesLoading, setSizesLoading] = useState(true);
  const [effects, setEffects] = useState([]);
  const [effectsLoading, setEffectsLoading] = useState(true);
  const [mats, setMats] = useState([]);
  const [matsLoading, setMatsLoading] = useState(true);
  const [fetchPhotoProp, setFetchPhotoProp] = useState(false);
  const [updatePhotoArray, setUpdatePhotoArray] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedEffect, setSelectedEffect] = useState("");
  const [selectedMat, setSelectedMat] = useState("");

  const fetchMaterialsData = async () => {
    try {
      setMaterialsLoading(true);
      const materialsData = await fetchMaterials();
      // console.log(materialsData.data.data);
      setMaterials(materialsData.data.data);
      setMaterialsLoading(false);
    } catch (error) {
      // console.log("fetch materials error:", error);
    }
  };
  const fetchSizesData = async () => {
    try {
      setSizesLoading(true);
      const sizesData = await fetchSizes(currentMaterial);
      // console.log(sizesData.data.sizes);
      setSizes(sizesData.data.sizes);
      setSizesLoading(false);
    } catch (error) {
      // console.log("fetch sizes error:", error);
    }
  };
  const fetchEffectsData = async () => {
    try {
      setEffectsLoading(true);
      const effectsData = await fetchEffects();
      // console.log(effectsData.data);
      setEffects(effectsData.data);
      setEffectsLoading(false);
    } catch (error) {
      // console.log("fetch effects error:", error);
    }
  };
  const fetchMatsData = async () => {
    try {
      setMatsLoading(true);
      const matsData = await fetchMats(currentMaterial);
      // console.log(matsData.data);
      setMats(matsData.data);
      setMatsLoading(false);
    } catch (error) {
      // console.log("fetch mats error:", error);
    }
  };

  const materialId = localStorage.getItem("materialId");
  const [currentMaterial, setCurrentMaterial] = useState(
    materialId ? materialId : 5
  );
  const [currentSize, setCurrentSize] = useState("");

  const handleLogout = async () => {
    try {
      setSideBarOpen(false);
      setLoading(true);
      const response = await logout();
      // console.log(response);
      if (response.status === "success") {
        toastSuccess("Successfully logged out");
        for (let key in window.localStorage) {
          if (
            key !== "theme" ||
            key !== "vividVisionsLang" ||
            key !== "lngAlreadyDetected"
          ) {
            window.localStorage.removeItem(key);
          }
        }
        Cookies.remove("userToken");
        Cookies.remove("curiousToken");
        Cookies.remove("adminToken");
        window.location.pathname = "/";
      }
    } catch (error) {
      // console.log("handle logout error", error);
    } finally {
      setLoading(false);
    }
  };

  const logNavigation = async (url) => {
    const formData = new FormData();
    formData.append("page_url", url);
    try {
      const response = await postUrlPage(formData);
      console.log(response);
    } catch (err) {
      console.error("log navigation err : ", err);
    }
  };

  const [totalPhotos, setTotalPhotos] = useState(0);
  const [totalPrice, setTotalPrice] = useState();
  const [updateTotalPrice, setUpdateTotalPrice] = useState(0);
  const [priceLoading, setPriceLoading] = useState(true);

  // checkout
  const [choosePaypal, setChoosePaypal] = useState(false);
  const [note, setNote] = useState("");

  const [address, setAddress] = useState({
    address_line_1: "",
    address_line_2: "",
    region_id: "",
    city: "",
    postcode: "",
    country_id: "",
    contact_number: "",
    full_name: "",
    email: userEmail || "",
    zip: "",
  });

  const [regionType, setRegionType] = useState("");

  const [cartItemsInfo, setCartItemsInfo] = useState({
    totalMaterialsCount: "",
    totalPictureCount: "",
    totalCost: "",
    totalCostWithDiscount: "",
    cartItems: [],
    discountCode: "",
    addressId: "",
    orderId: "",
    orderNumber: "",
  });
  const [cartItemsInfoUpdate, setCartItemsInfoUpdate] = useState(0);
  const [checkoutDataLoading, setCheckoutDataLoading] = useState(false);

  // art photos
  const { t } = useTranslation();
  const [selectedSortValue, setSelectedSortValue] = useState(t("Latest"));
  const [sortKey, setSortKey] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentStatusId, setCurrentStatusId] = useState(2);
  const [categoryId, setCategoryId] = useState("");

  // Pagination
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationKey, setPaginationKey] = useState(0);

  // Payment Details
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");

  // dashboard

  const [dashItem, setDashItem] = useState(Boolean);
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");

  // select language
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [myLang, setMyLang] = useState("English");
  const [dir, setDir] = useState("ltr");
  const contextValue = {
    updatePhotoArray,
    setUpdatePhotoArray,
    selectedMaterial,
    setSelectedMaterial,
    selectedSize,
    setSelectedSize,
    selectedEffect,
    setSelectedEffect,
    selectedMat,
    setSelectedMat,
    materials,
    sizes,
    effects,
    mats,
    materialsLoading,
    sizesLoading,
    effectsLoading,
    matsLoading,
    totalPhotos,
    setTotalPhotos,
    logNavigation,
    totalPrice,
    setTotalPrice,
    updateTotalPrice,
    setUpdateTotalPrice,
    priceLoading,
    setPriceLoading,
    currentMaterial,
    setCurrentMaterial,
    currentSize,
    setCurrentSize,
    fetchMaterialsData,
    fetchSizesData,
    fetchEffectsData,
    fetchMatsData,
    handleLogout,
    loading,
    setLoading,
    categoryLoading,
    setCategoryLoading,
    fetchPhotoProp,
    setFetchPhotoProp,
    note,
    setNote,
    address,
    setAddress,
    regionType,
    setRegionType,
    cartItemsInfo,
    setCartItemsInfo,
    cartItemsInfoUpdate,
    setCartItemsInfoUpdate,
    checkoutDataLoading,
    setCheckoutDataLoading,
    choosePaypal,
    setChoosePaypal,
    dashItem,
    setDashItem,
    filterStatus,
    setFilterStatus,
    selectedFilter,
    setSelectedFilter,
    selectedLanguage,
    setSelectedLanguage,
    myLang,
    setMyLang,
    dir,
    setDir,
    selectedSortValue,
    setSelectedSortValue,
    sortKey,
    setSortKey,
    selectedCategory,
    setSelectedCategory,
    currentStatusId,
    setCurrentStatusId,
    categoryId,
    setCategoryId,
    totalPages,
    setTotalPages,
    itemPerPage,
    setItemPerPage,
    pageNumber,
    setPageNumber,
    paginationKey,
    setPaginationKey,
    selectedItemId,
    setSelectedItemId,
    selectedOrderId,
    setSelectedOrderId,
    lazyLoading,
    handleImageError,
  };

  return (
    <PhotoContext.Provider value={contextValue}>
      {children}
    </PhotoContext.Provider>
  );
};
