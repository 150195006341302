import React, { useContext } from "react";
import { LuPlane } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../Context/ThemeContext";
import MainButton from "../../../../Shared/Components/MainButton";
import { PhotoContext } from "../../../../Context/PhotoContext";

function Landing() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { totalPhotos } = useContext(PhotoContext);
  const lng = localStorage.getItem("vividVisionsLang") || "English";

  return (
    <div className="flex items-center gap-[80px] mb-5 md:mt-5 md:my-20 pt-6 max-md:flex-col-reverse max-md:gap-2 max-md:mb-[40px] ">
      <div className="basis-[50%] ml-auto max-md:mx-auto">
        <div className="md:ml-auto md:grid md:grid-cols-2 md:gap-[20px]">
          <div
            className={`${lng === "Arabic" ? "md:mr-auto" : "md:ml-auto"
              } mb-[20px] md:max-w-[236px] md:max-h-[236px] md:my-auto`}
          >
            <img
              className="w-full h-full rounded-lg max-md:max-h-[440px] max-md:aspect-square object-cover object-top"
              src="https://backend.tecrek.com/public/Photos/80.jpeg"
              alt=""
            />
          </div>
          <div className="flex gap-[20px] md:flex-col">
            <div className="basis-[50%] md:max-w-[230px]">
              <img
                className="w-full  rounded-lg"
                src="https://backend.tecrek.com/public/Photos/20.jpeg"
                alt=""
              />
            </div>
            <div className="hidden md:block basis-[50%] md:max-w-[450px]">
              <img
                className="w-full  rounded-lg"
                src="https://backend.tecrek.com/public/Photos/7.30.jpeg"
                alt=""
              />
            </div>
            <div className="block md:hidden basis-[50%] md:max-w-[450px]">
              <img
                className="w-full h-full rounded-lg"
                src="https://backend.tecrek.com/public/Photos/7.10.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${theme === "dark" && "text-DarkMainText"
          } basis-[50%] max-md:text-center`}
      >
        <h1 className="font-bold text-[65px] leading-[4rem] mb-6 max-md:mb-4 max-xl:font-bold max-xl:text-[45px] max-xl:leading-none 2xl:max-w-[500px] max-md:text-[30px]">
          {t("Turn your photos into stunning wall art.")}
        </h1>
        <div className="max-md:mb-4 max-md:flex max-md:flex-col max-md:items-center">
          <p className="text-lg mb-6 max-md:mb-4 font-semibold">
            {t(
              "Create beautiful walls filled with memories using your favorite photos."
            )}
          </p>
          <div className="hidden md:block md:w-[312px] h-[60px] rounded-[10px] mb-[24px]">
            <MainButton
              link={"/edit-photos"}
              content={
                totalPhotos && totalPhotos > 0
                  ? `${t("Continue Design")} (${totalPhotos})`
                  : t("Start Design")
              }
              className="w-full h-full"
            />
          </div>
          <div className="flex gap-3 items-center">
            <LuPlane size={25} />
            <h1 className="font-bold text-lg ">
              {t("Free worldwide shipping!")}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
