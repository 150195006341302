import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import React, { useEffect } from "react";
import EditOnePhoto from "../Pages/EditOnePhoto";

const EditOnePhotoWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  let leftEditPage = false;

  useEffect(() => {
    const handleNavigation = () => {
      // Check if the user is currently on the edit-photos/:id page
      if (location.pathname.startsWith("/edit-photos/")) {
        // If the user leaves the edit-photos/:id page, set the flag
        if (!location.pathname.startsWith("/edit-photos/")) {
          leftEditPage = true;
        }
      }
    };

    handleNavigation();
  }, [location]);

  useEffect(() => {
    const handlePopState = (event) => {
      // When user clicks the back button, check if they left the edit-photos/:id page
      if (
        location.pathname.startsWith("/edit-photos/") &&
        leftEditPage &&
        navigationType === "POP"
      ) {
        // Prevent going back to the edit page
        event.preventDefault();
        // Navigate them to another page, e.g., the main edit-photos page
        navigate("/edit-photos", { replace: true });
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location, navigate, navigationType]);

  return <EditOnePhoto />;
};

export default EditOnePhotoWrapper;
