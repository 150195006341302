import React, { useContext } from "react";
import MaterialBoxItem from "./MaterialBoxItem";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTranslation } from "react-i18next";
import { updatePhotoInCart } from "../../Api/Editphotos";
import { useTheme } from "../../Context/ThemeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

function MaterialList({ handleOpenPopup }) {
  const { id } = useParams();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    setUpdateTotalPrice,
    setUpdatePhotoArray,
    selectedMaterial,
    setSelectedMaterial,
    materials,
    currentMaterial,
    setCurrentMaterial,
    currentSize,
    materialsLoading,
  } = useContext(PhotoContext);

  const handleMaterialClick = async (materialId) => {
    setSelectedMaterial(materialId);
    localStorage.setItem("materialId", materialId);
    const requestBody = {
      material_id: materialId,
      ...(currentSize === 14 && { size_id: 13 }),
      ...(currentSize === 8 && { size_id: 7 }),
      ...(currentSize === 1 && currentMaterial !== 5 && { size_id: 10 }),
    };

    try {
      const response = await updatePhotoInCart(
        id ? `updateCartItem?id=${id}` : `updateCartItem`,
        requestBody,
        id
      );
      // console.log("Success:", response.data);
      setCurrentMaterial(materialId);
      setUpdateTotalPrice((prev) => prev + 1);
      setUpdatePhotoArray((prev) => prev + 1);
    } catch (error) {
      console.error("Update photo in cart error:", error);
    }
  };

  return (
    <div
      className={`${theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
        } max-w-[530px] max-md:max-w-full rounded-[10px] w-fit min-w-[230px] overflow-y-auto flex flex-col max-h-full`}
    >
      <div className="flex justify-between items-center m-[20px]">
        <h1
          className={`${theme === "dark" ? "text-DarkMainText/70" : "text-SecondText"
            } font-[600] text-[16px] leading-[16px]`}
        >
          {t("Choose Material")}
        </h1>
        <div
          onClick={() => handleOpenPopup("material")}
          className="text-PrimaryColor cursor-pointer p-[1em] -m-[1em]"
        >
          {t("Completed")}
        </div>
      </div>
      <div className="flex px-[20px] pb-[24px] gap-[12px] overflow-hidden w-full sizeSwiper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={20}
          slidesPerView={"auto"}
          navigation
        >
          {materialsLoading
            ? Array.from({ length: 5 }).map((_, index) => (
              <SwiperSlide
                key={index}
                className="min-w-[130px] max-w-[130px] min-h-[150px]"
              >
                <div className="w-full h-full">
                  <Skeleton width={`100%`} className="h-full" />
                </div>
              </SwiperSlide>
            ))
            : materials.map((material) => (
              <SwiperSlide
                key={material.id}
                className="min-h-[150px] max-h-[150px] max-w-[130px]"
              >
                <MaterialBoxItem
                  onClick={() => handleMaterialClick(material.id)}
                  title={material.name}
                  isActive={selectedMaterial === material.id}
                  image={material.image}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MaterialList;
