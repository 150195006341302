import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RETURN_URL } from "../../Api/api";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStripeReady, setIsStripeReady] = useState(false);

  useEffect(() => {
    if (!stripe || !elements) {
      setIsStripeReady(false);
      return;
    }

    setIsStripeReady(true);
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      setMessage("Payment setup incomplete.");
    }
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setMessage("Payment service is not available.");
      setIsLoading(false);
      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${RETURN_URL}/payment-details/callback/${orderNumber}`,
      },
    });

    if (error) {
      setMessage(error.message || "An unexpected error occurred.");
      setIsLoading(false);
      return;
    }

    setMessage(`Payment status: ${paymentIntent.status}`);
    setIsLoading(false);
  };

  return (
    <div
      className={`bg-gray-100 min-h-screen flex flex-col items-center justify-center`}
    >
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg p-8 bg-white shadow-xl rounded-xl"
      >
        <h2 className="text-2xl text-MainText font-semibold text-center mb-6">
          {t("Payment Checkout")}
        </h2>
        <Link
          to={`/payment-details/${orderNumber}`}
          className="text-blue-500 hover:text-blue-700 transition duration-200 ease-in-out mb-6 block text-center"
        >
          {t("Go back")}
        </Link>
        <div className="mb-6">
          <PaymentElement />
        </div>
        <button
          disabled={!isStripeReady || isLoading}
          type="submit"
          className={`w-full bg-blue-600 text-white rounded-lg py-3 font-semibold ${
            isLoading ? "bg-blue-300" : "hover:bg-blue-700"
          } transition duration-150 ease-in-out flex items-center justify-center`}
        >
          {isLoading ? (
            <AiOutlineLoading3Quarters className="animate-spin mr-2" />
          ) : (
            t("Submit Payment")
          )}
        </button>
        {message && (
          <div className="mt-4 text-center text-red-600">{message}</div>
        )}
      </form>
    </div>
  );
};

export default CheckoutForm;
