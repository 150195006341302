import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";

function SidebarItem({ link, content, icon, onClick }) {
  const { theme } = useTheme();
  return (
    <Link
      onClick={onClick}
      to={link}
      className={`${
        theme === "dark"
          ? "text-DarkMainText hover:bg-DarkBoxBg"
          : "text-MainText hover:bg-[#fbf9f9]"
      } flex items-center gap-5 duration-100 w-full min-w-[237px] max-w-[300px] min-h-[52px] rounded-[5px] ml-[12px] pl-[10px] pr-[17px]`}
    >
      {icon}
      <h1 className="text-[16px] font-[500] ">{content}</h1>
    </Link>
  );
}

export default SidebarItem;
