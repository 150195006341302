import { apiClient } from "./api";

export const fetchAllOrdersForUser = async (page) => {
  try {
    const response = await apiClient.get(`/api/getAllOrderUser?page=${page}`);
    return response.data.data;
  } catch (error) {
    console.error("fetch all orders err", error);
  }
};

export const fetchOneOrderForUser = async (order_number) => {
  try {
    const response = await apiClient.get(`api/OrderDetailsUser?order_number=${order_number}`);
    return response.data.data;
  } catch (error) {
    console.error(`fetch order with number ${order_number} err`, error);
  }
};
