import React, { useContext } from "react";
import { useTheme } from "../../Context/ThemeContext";
import ProfileListItem from "./ProfileListItem";
import { PopupContext } from "../../Context/PopupContext";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";
import { PhotoContext } from "../../Context/PhotoContext";

function ProfileList() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { setProfileListOpen, setFAQsOpen, setInputPromoCodeOpen } =
    useContext(PopupContext);
  const { handleLogout } = useContext(PhotoContext);
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const profileListRef = useOutsideClick(() => setProfileListOpen(false));

  return (
    <div
      ref={profileListRef}
      className={`${theme === "dark" ? "bg-DarkMainBg" : "bg-MainBg"} ${
        lng === "Arabic" ? "left-0" : "right-0"
      } z-10 absolute flex flex-col rounded-[12px] photoSelectMenuBoxShadow overflow-hidden`}
    >
      {/* <ProfileListItem
        onClick={() => {
          setInputPromoCodeOpen(true);
          setProfileListOpen(false);
        }}
        content={t("Discount Codes")}
      /> */}
      <ProfileListItem
        onClick={() => setProfileListOpen(false)}
        link="/my-orders"
        content={t("My Orders")}
      />
      <ProfileListItem
        onClick={() => {
          setFAQsOpen(true);
          setProfileListOpen(false);
        }}
        content={t("FAQs")}
      />
      <ProfileListItem
        onClick={() => setProfileListOpen(false)}
        link={`/terms-of-service`}
        content={t("Terms of Service")}
      />
      <ProfileListItem onClick={() => handleLogout()} content={t("Logout")} />
    </div>
  );
}

export default ProfileList;
