import { apiClient } from "./api";

export const fetchOrderDetailsForUser = async (order_number) => {
  try {
    const response = await apiClient.get(
      `/api/OrderDetailsUser?order_number=${order_number}`
    );
    return response.data.data;
  } catch (error) {
    console.error("fetch order details for user err", error);
    if (error.response.data.errors[0] === "The selected order number is invalid.") {
      window.location.pathname = "/";
    }
  }
};

export const deleteItemFromOrder = async (cartItemId, orderId) => {
  try {
    const response = await apiClient.delete(
      `/api/delete/order?cartItem_id=${cartItemId}&order_id=${orderId}`
    );
    return response.data;
  } catch (error) {
    console.error("delete item from order err", error);
    
  }
};

export const paymentIntent = async (order_number) => {
  try {
    const response = await apiClient.post(
      `/api/createPaymentIntent?order_number=${order_number}`
    );
    return response.data;
  } catch (error) {
    console.error("payment intent err", error);
  }
};

export const payment = async (paymentId) => {
  try {
    const response = await apiClient.post(
      `/api/confirmPaymentIntent?payment_intent_id=${paymentId}`
    );
    return response.data;
  } catch (error) {
    console.error("payment err", error);
  }
};
