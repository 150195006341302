import React, { useContext, useEffect, useState } from "react";
import {
  fetchTecrekFoundedCountries,
  fetchTecrekVisitors,
} from "../../Api/Dashboard";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../Shared/Components/CustomPagination";

function TecrekVisitorsView() {
  const { theme } = useTheme();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [visitors, setVisitors] = useState([]);
  const { loading, setLoading } = useContext(PhotoContext);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
      const [from, setFrom] = useState("");
      const [to, setTo] = useState("");
      const [total, setTotal] = useState("");
      const [paginationKey, setPaginationKey] = useState(0);
      const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        // console.log(selectedPage);
        setPageNumber(selectedPage);
      };
      const getCountries = async () => {
        try {
          const response = await fetchTecrekFoundedCountries();
          console.log(response);
          setCountries(response.data);
        } catch (err) {
          console.error("get countries data err : ", err);
        }
      };
      const getVisitors = async () => {
        try {
          setLoading(true);
          const response = await fetchTecrekVisitors(pageNumber, country);
          // console.log(response.data);
          setVisitors(response.data.data);
          setTotalPages(response.data.last_page);
          setItemPerPage(response.data.per_page);
          setPageNumber(response.data.current_page);
          setFrom(response.data.from);
          setTo(response.data.to);
          setTotal(response.data.total);
        } catch (error) {
          console.error("fetch visitors data error : ", error);
        } finally {
          setLoading(false);
        }
      };
      useEffect(() => {
        getCountries();
      }, []);
      useEffect(() => {
        getVisitors();
      }, [pageNumber, country]);
      return (
        <div>
          <h1 className="text-2xl font-semibold">Tecrek Visitors</h1>
          <div className="mt-4 flex flex-col gap-3">
            <select
              className="flex items-center cursor-pointer outline-none w-fit gap-2 bg-PrimaryColor text-white rounded-[20px] border-2 px-4 py-2 max-md:px-2 max-md:py-1 font-[700] hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg duration-300"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
                setPaginationKey((prev) => prev + 1);
                setPageNumber(1);
              }}
            >
              <option
                className={`${
                  theme === "dark"
                    ? "bg-DarkBoxBg text-DarkMainText"
                    : "bg-white text-MainText"
                }`}
                value=""
              >
                All Countries
              </option>
              {countries.map((country) => (
                <option
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg text-DarkMainText"
                      : "bg-white text-MainText"
                  }`}
                  key={country.countryName}
                  value={country.countryName}
                >
                  {country.countryName}
                </option>
              ))}
            </select>
            <table
              className={`${
                theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
              } w-full table-auto`}
            >
              <thead>
                <tr>
                  <th className="border px-4 py-2">Id</th>
                  <th className="border px-4 py-2">Ip Address</th>
                  <th className="border px-4 py-2">Request Time</th>
                  <th className="border px-4 py-2">Count</th>
                  <th className="border px-4 py-2">Country</th>
                  <th className="border px-4 py-2">Region</th>
                  <th className="border px-4 py-2">City</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  Array.from({ length: 20 }).map((_, outInex) => (
                    <tr key={outInex}>
                      {Array.from({ length: 7 }).map((_, index) => (
                        <td key={index}>
                          <Skeleton width={"100%"} height={35} />
                        </td>
                      ))}
                    </tr>
                  ))
                ) : visitors && visitors.length > 0 ? (
                  visitors.map((visitor, index) => (
                    <tr key={index}>
                      <td className="border text-center px-4 py-2">
                        {visitor.id}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {visitor.ip_address}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {visitor.request_time}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {visitor.count}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {visitor.countryName}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {visitor.regionName}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {visitor.cityName}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>No Data</tr>
                )}
              </tbody>
            </table>
            <CustomPagination
              key={paginationKey}
              totalPages={totalPages}
              currentPage={pageNumber}
              itemPerPage={itemPerPage}
              handlePageClick={handlePageClick}
              from={from}
              to={to}
              total={total}
            />
          </div>
        </div>
      );
}

export default TecrekVisitorsView;
