import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import americaFlag from "../../assets/imgs/americaFlag.png";
import germanyFlag from "../../assets/imgs/germanyFlag.png";
import saudiArabiaFlag from "../../assets/imgs/saudiArabiaFlag.png";
import { useTranslation } from "react-i18next";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import { useTheme } from "../../Context/ThemeContext";
import Popup from "../../Shared/Components/Popup";

function SelectLanguage() {
  const { theme } = useTheme();
  const { i18n, t } = useTranslation();
  const { setSelectedLanguage, setDir, setSortKey } = useContext(PhotoContext);
  const { languageOpen, setLanguageOpen } = useContext(PopupContext);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("vividVisionsLang", lng);
    setSelectedLanguage(lng);
    setDir(lng === "Arabic" ? "rtl" : "ltr");
    setLanguageOpen(false);
    setSortKey((prev) => prev + 1);
  };
  return (
    <Popup
      toggleSwitch={languageOpen}
      children={
        <div
          className={`${
            theme === "dark"
              ? "bg-DarkMainBg text-DarkMainText"
              : "text-MainText bg-MainBg"
          } rounded-[20px] max-md:rounded-none z-50 md:w-[460px] max-md:w-full`}
        >
          <div className="flex items-center justify-center h-[50px] relative py-[2px] w-full">
            <AiOutlineClose
              className="absolute cursor-pointer left-[10px]"
              onClick={() => setLanguageOpen(false)}
              size={20}
            />
            <h1
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } text-[19px] font-[700]`}
            >
              {t("Choose Language")}
            </h1>
          </div>
          <div>
            <ul className="max-w-[320px] my-[10px] mx-auto">
              <li
                onClick={() => {
                  changeLanguage("English");
                }}
                className="flex items-center gap-6 border-b border-b-[#ededed] cursor-pointer py-[20px]"
              >
                <img
                  className="w-[28px] h-[20px] rounded-sm"
                  src={americaFlag}
                  alt=""
                />
                <h1>{t("English")}</h1>
              </li>
              <li
                onClick={() => {
                  changeLanguage("Deutsch");
                }}
                className="flex items-center gap-6 cursor-pointer py-[20px]"
              >
                <img
                  className="w-[28px] h-[20px] rounded-sm"
                  src={germanyFlag}
                  alt=""
                />
                <h1>{t("German")}</h1>
              </li>
              {/* <li
                onClick={() => {
                  changeLanguage("Arabic");
                }}
                className="flex items-center gap-6 cursor-pointer py-[20px]"
              >
                <img
                  className="w-[28px] h-[20px] rounded-sm"
                  src={saudiArabiaFlag}
                  alt=""
                />
                <h1>{t("Arabic")}</h1>
              </li> */}
            </ul>
          </div>
        </div>
      }
      overLayClassName="z-40"
      className={`${
        theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
      } z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] languageBoxShadow duration-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
      onClose={() => setLanguageOpen(false)}
    />
  );
}

export default SelectLanguage;
