import React, { useContext } from "react";
import { PopupContext } from "../../Context/PopupContext";
import { PhotoContext } from "../../Context/PhotoContext";
import Popup from "../../Shared/Components/Popup";
import { BsFillCameraFill } from "react-icons/bs";
import { addPhotoToCart } from "../../Api/Editphotos";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { toastError } from "../../Shared/Components/ToastNotification/Toast";

function ProcessOfAddPhoto() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { uploadSourceOpen, setUploadSourceOpen } = useContext(PopupContext);
  const { setLoading, setUpdateTotalPrice, setUpdatePhotoArray } =
    useContext(PhotoContext);

  const handleChange = async (event) => {
    const files = Array.from(event.target.files);

    // Check file sizes immediately
    //worked
    const largeFiles = files.filter((file) => file.size / (1024 * 1024) > 10);

    if (largeFiles.length > 0) {
      toastError(
        `File size exceeds 10 MB limit. Please upload smaller images.`
      );
      setUploadSourceOpen(false);
      setLoading(false);
      return; // Exit the function early
    }

    const arrayIds = files.map((item) => ({
      material_id: 5,
      size_id: 3,
      url_photo: item,
      effect_id: 1,
      mat_id: 1,
    }));

    try {
      setLoading(true);
      setUploadSourceOpen(false);
      const response = await addPhotoToCart(arrayIds);
      // console.log(response);
      if (response.success === "true") {
        setUpdatePhotoArray((prev) => prev + 1);
        // window.location.reload();
      }
    } catch (err) {
      console.error("add photo/s to cart error", err);
    } finally {
      setUpdateTotalPrice((prev) => prev + 1);
    }
  };

  return (
    <div>
      <Popup
        toggleSwitch={uploadSourceOpen}
        children={
          <div
            className={`flex flex-col ${theme === "dark"
                ? "text-DarkMainText hover:bg-white/20"
                : "text-MainText hover:bg-[#f7f7f7]"
              } rounded-[12px] w-full duration-300`}
          >
            <input
              className="hidden"
              multiple
              type="file"
              id="input"
              accept="image/*"
              onChange={handleChange}
            />
            <label
              htmlFor="input"
              className="cursor-pointer px-5 flex items-center gap-4 h-[75px]"
            >
              <BsFillCameraFill className="text-PrimaryColor" size={25} />
              <h1 className="font-[600] text-[16px]">{t("Add Photos")}</h1>
            </label>
          </div>
        }
        overLayClassName={`z-20`}
        className={`${uploadSourceOpen
            ? "left-[50%] -translate-x-[50%] bottom-[20px] fixed"
            : "-translate-y-[100%]"
          } ${theme === "dark" ? "bg-DarkMainBg" : "bg-white"
          } z-30 w-[500px] max-sm:w-[240px] mx-auto duration-300 rounded-[12px]`}
        onClose={() => setUploadSourceOpen(false)}
      />
    </div>
  );
}

export default ProcessOfAddPhoto;
