import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { updateUserType } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";

function UpdateUserType({
  id,
  userName,
  userEmail,
  currentType,
  refreshUsers,
}) {
  // console.log(id);
  const { theme } = useTheme();
  const [name, setName] = useState(userName);
  const [email, setEmail] = useState(userEmail);
  const [currentUserType, setCurrentUserType] = useState(currentType);
  const [loading, setLoading] = useState(false);
  const [newUserType, setNewUserType] = useState(1);
  const { updateUserTypeOpen, setUpdateUserTypeOpen } =
    useContext(PopupContext);
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await updateUserType(id, newUserType);
      if (response.status === 200) {
        // console.log("User type updated successfully!");
        refreshUsers(); // Call the callback function to refresh users
      } else {
        // console.log(`Request failed with status code ${response.status}`);
      }
    } catch (error) {
      // console.log( "There was a problem with the axios operation: ",error.message);
} finally {
  setUpdateUserTypeOpen(false);
}
  };

return (
  <div
    className={`${theme === "dark" && "bg-DarkBoxBg"
      } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
  >
    <form
      onSubmit={handleSubmit}
      className={` ${theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
    >
      <div
        className={`${theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-[18px] leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
      >
        <div className="hover:bg-black/10 p-[5px] rounded-full">
          <AiOutlineClose
            className="cursor-pointer"
            onClick={() => {
              setUpdateUserTypeOpen(false);
            }}
            size={26}
          />
        </div>
        <h1
          className={`${theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
        >
          Update User Type
        </h1>
        <button
          type="submit"
          className={`${loading && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-[18px] cursor-pointer font-[700] text-PrimaryColor hover:underline duration-300`}
        >
          {loading ? <ProcessSpinner /> : "Done"}
        </button>
      </div>
      <div
        className={`${theme === "dark"
          ? "text-DarkMainText/70 bg-DarkBoxBg"
          : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
      >
        <div className="flex flex-col">
          <h1 className="mb-[0.25rem]">User Name</h1>
          <input
            readOnly
            className={`${theme === "dark"
              ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
              : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
            type="text"
            placeholder="User Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <h1 className="mb-[0.25rem]">User Email</h1>
          <input
            readOnly
            className={`${theme === "dark"
              ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
              : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
            type="text"
            placeholder="User Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <h1 className="mb-[0.25rem]">Current User Type</h1>
          <input
            readOnly
            className={`${theme === "dark"
              ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
              : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
            type="text"
            placeholder="User Email"
            value={currentUserType}
            onChange={(e) => setCurrentUserType(e.target.value)}
          />
          <h1 className="mb-[0.25rem]">Select New User Type</h1>
          <select
            className={`${theme === "dark"
              ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
              : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
            value={newUserType}
            onChange={(e) => setNewUserType(e.target.value)}
          >
            <option disabled value="">
              Select
            </option>
            <option value={1}>admin</option>
            <option value={2}>curious</option>
            <option value={3}>user</option>
            <option value={4}>user to user</option>
          </select>
        </div>
      </div>
    </form>
  </div>
);
}

export default UpdateUserType;
