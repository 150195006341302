import { apiClient } from "./api";
import { toastError } from "../Shared/Components/ToastNotification/Toast";

const deviceId = localStorage.getItem('deviceId') || 'device-' + Math.random().toString(36).substring(2, 15);
export const postCuriousRegister = async () => {
  try {
    const response = await apiClient.post(`/api/register`, null, {
      headers: {
        "Device-Id": deviceId
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const loginAndSendCode = async (Data) => {
  try {
    const response = await apiClient.post(`/api/login/register`, Data, {
      headers: {
        "Device-Id": deviceId
      }
    });
    return response.data;
  } catch (error) {
    console.error("login and send code err : ", error);
    console.error("login err : ", error.response.data.errors[0]);
    toastError(error.response.data.errors[0]);
  }
};

export const verifyCode = async (Data) => {
  try {
    const response = await apiClient.post(`/api/verify-email`, Data);
    return response.data;
  } catch (error) {
    console.error("verify email err : ", error.response.data.errors[0]);
    toastError(error.response.data.errors[0]);
  }
};

export const postRegisterOrLogin = async (EndPoint, Data) => {
  try {
    const response = await apiClient.post(`/api/${EndPoint}`, Data);
    return response.data;
  } catch (error) {
    console.error("register or login err", error.response.data.errors[0]);
    toastError(error.response.data.errors[0]);
  }
};

export const googleLogin = async () => {
  try {
    const response = await apiClient.get(`/api/auth/google`);
    return response;
    // Construct the Google OAuth URL with required parameters
  } catch (error) {
    console.error("google login err", error);
  }
};

export const logout = async () => {
  try {
    const response = await apiClient.get(`/api/logout`);
    return response.data;
  } catch (error) {
    // console.log("logout err", error);
  }
};
