import React, { useContext } from "react";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTheme } from "../../Context/ThemeContext";

function FeaturesItem({ image, title, description }) {
  const { theme } = useTheme();
  const { handleImageError } = useContext(PhotoContext);

  return (
    <div className={`${theme === "dark" && "text-DarkMainText"} text-center`}>
      <img
        className="mx-auto w-[60px] h-[60px] mb-3"
        src={image}
        alt=""
        onError={handleImageError}
      />
      <h1 className="font-bold text-lg">{title}</h1>
      <p>{description}</p>
    </div>
  );
}

export default FeaturesItem;
