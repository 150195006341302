import React from "react";
import { Link } from "react-router-dom";

function MainButton({ link, className, content, onClick, type }) {
  if (type === "submit") {
    return (
      <button
        onClick={onClick}
        type={type}
        className={`${className} text-center font-bold text-white rounded-[12px] bg-PrimaryColor hover:bg-HoverPrimaryColor outline-none duration-300`}
      >
        {content}
      </button>
    );
  } else {
    return (
      <Link to={link} onClick={onClick} className="w-fit">
        <button
          type={type}
          className={`${className} text-center font-bold text-white rounded-[12px] bg-PrimaryColor hover:bg-HoverPrimaryColor outline-none duration-300`}
        >
          {content}
        </button>
      </Link>
    );
  }
}

export default MainButton;
