import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function EffectBoxItem({ isActive, image, title, onClick }) {
  const { theme } = useTheme();
  return (
    <div
      onClick={onClick}
      className={`${
        isActive
          ? "text-PrimaryColor cursor-default pointer-events-none"
          : theme === "dark"
          ? "text-DarkMainText/70 cursor-pointer"
          : "text-SecondText cursor-pointer"
      } w-[130px] h-full min-h-full duration-300 flex flex-col items-center rounded-[4px] overflow-hidden`}
    >
      <div
        className={`${
          isActive
            ? "border-PrimaryColor shadow-PrimaryColor"
            : "border-transparent shadow-[#ebe7e7]"
        } border-[2px] rounded-[5px] max-w-full max-h-[100px] w-full shadow-sm`}
      >
        <img
          className={` ${
            title === "silver"
              ? "silver"
              : title === "noir"
              ? "noir"
              : title === "vivid"
              ? "vivid"
              : title === "dramatic"
              ? "dramatic"
              : ""
          } w-full h-full object-cover`}
          src={image}
          alt=""
        />
      </div>
      <h1
        className={`${
          isActive
            ? "text-PrimaryColor "
            : theme === "dark"
            ? "text-DarkMainText/70"
            : "text-SecondText"
        } capitalize text-[13px] font-[600] max-md:text-[10px]`}
      >
        {title}
      </h1>
    </div>
  );
}

export default EffectBoxItem;
