import { useState, useEffect, useContext } from "react";
import { PhotoContext } from "../../Context/PhotoContext";
import { fetchAccessTokens } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../Shared/Components/CustomPagination";
import SearchInput from "../Components/SearchInput";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";

const AccessTokens = () => {
  const { theme } = useTheme();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [total, setTotal] = useState("");
  const { loading, setLoading } = useContext(PhotoContext);

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);
  const search = async (e) => {
    setQuery(e.target.value);
    query && (await fetchData());
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAccessTokens(pageNumber, query);
      console.log(response);
      setData(response.data.data);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setPageNumber(response.data.current_page);
      setFrom(response.data.from);
      setTo(response.data.to);
      setTotal(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Ensure loading stops regardless of the fetch outcome
      query && setSearchLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [pageNumber, query]);

  return (
    <div>
      <h1 className="text-2xl font-semibold">Access Tokens</h1>
      <div className="flex flex-col gap-3 mt-4">
        <SearchInput
          query={query}
          search={(e) => search(e)}
          loading={searchloading}
          placeHolder={"Search"}
        />
        <div>
          <table
            className={`${
              theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
            } w-full table-auto`}
          >
            <thead>
              <tr>
                <th className="border px-4 py-2">Id</th>
                <th className="border px-4 py-2">Name</th>
                <th className="border px-4 py-2">Email</th>
                <th className="border px-4 py-2 relative">
                  <HiDotsVertical className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]" />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 20 }).map((_, outInex) => (
                  <tr key={outInex}>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <td key={index}>
                        <Skeleton width={"100%"} height={35} />
                      </td>
                    ))}
                  </tr>
                ))
              ) : data && data.length > 0 ? (
                data.map((user, index) => (
                  <tr key={index} className="">
                    <td className="border text-center px-4 py-2">{user.id}</td>
                    <td className="border text-center px-4 py-2">
                      {user.name}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.email}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.login_log.length > 0 ? (
                        <Link
                          to={`/dashboard/access-tokens/${user.id}`}
                          className="text-PrimaryColor font-bold cursor-pointer hover:underline duration-300"
                        >
                          view
                        </Link>
                      ) : (
                        "No data"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div>No Data</div>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          totalPages={totalPages}
          currentPage={pageNumber}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
};

export default AccessTokens;
