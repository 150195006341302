import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import Popup from "../../Shared/Components/Popup";
import { PopupContext } from "../../Context/PopupContext";
import { useTheme } from "../../Context/ThemeContext";
import { AiOutlineClose } from "react-icons/ai";
import { loginAndSendCode, verifyCode } from "../../Api/Auth";
import {
  toastError,
  toastLoading,
} from "../../Shared/Components/ToastNotification/Toast";
import { useTranslation } from "react-i18next";

function LogInWithCode() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const userEmail = localStorage.getItem("email");

  //   const [googleAccessToken, setGoogleAccessToken] = useState();
  const [googleEmail, setGoogleEmail] = useState();
  const [googleLogin, setGoogleLogin] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [code, setCode] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const login = useGoogleLogin({
  //     onSuccess: (tokenResponse) => {
  //       setGoogleAccessToken(tokenResponse.access_token);
  //       getUserEmail(tokenResponse.access_token);
  //     },
  //   });
  const getUserEmail = async (accessToken) => {
    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" +
      accessToken
    );
    setGoogleEmail(response.data.email);
    setLoading(true);
    await loginAndSendCode(response.data.email);
    setLoading(false);
    setGoogleLogin(true);
    setLoginOpen(false);
    setCodeOpen(true);
    return response.data.email;
  };

  const showButton = () => {
    setTimeout(() => {
      setShowResendButton(true);
    }, 30000);
  };

  const {
    loginOpen,
    setLoginOpen,
    codeOpen,
    setCodeOpen,
    showResendButton,
    setShowResendButton,
  } = useContext(PopupContext);
  const emailSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    const formData = new FormData();
    formData.append("email", email);
    if (!email || !isValidEmail) {
      toastError(t("Please enter a valid email"));
    } else {
      setEmailLoading(true);
      try {
        setShowResendButton(false);
        const response = await loginAndSendCode(formData);
        // console.log(response);
        if (response.status === "success") {
          setLoginOpen(false);
          setCodeOpen(true);
          showButton();
        }
      } catch (error) {
        console.error("login and send code err : ", error);
      } finally {
        setEmailLoading(false);
      }
    }
  };

  const codeSubmit = async (e) => {
    e.preventDefault();
    setCodeLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("verification_code", code);
    try {
      setShowResendButton(false);
      // googleLogin ? await postCode(googleEmail) : await postCode(email);
      const response = await verifyCode(formData);
      // console.log(response);
      if (response.status === "success") {
        Cookies.set("userToken", response.data.token, { expires: 365 });
        localStorage.setItem("email", response.data.user.email);
        setEmail("");
        setLoginOpen(false);
        setCodeOpen(false);
        window.location.reload();
        localStorage.removeItem("giftNote");
      }
    } catch (error) {
      console.error(error);
      showButton();
    } finally {
      setCodeLoading(false);
      setCode("");
    }
  };

  const resendCode = async () => {
    const formData = new FormData();
    formData.append("email", email);
    const toastId = toastLoading(
      t("We'll send the code in a matter of seconds")
    );
    try {
      setShowResendButton(false);
      const response = await loginAndSendCode(formData);
      // console.log(response);
      toast.dismiss(toastId);
      showButton();
    } catch (error) {
      console.error("resend code error : ", error);
    }
  };
  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=295377854184-kba7la0jsre66mt97atna38hn1ng7rj8.apps.googleusercontent.com&response_type=code&scope=email%20profile&redirect_uri=http://127.0.0.1:3000/api/auth/google/callback`;
      // Redirect the user to Google's OAuth2 endpoint
      window.location.href = googleAuthUrl;
    } catch (err) {
      // console.log("google login err", err);
    }
    setLoading(false);
  };
  return (
    <>
      <Popup
        toggleSwitch={loginOpen}
        children={
          <div
            className={`rounded-[20px] max-md:rounded-none p-[36px] md:w-[450px] flex flex-col gap-4 m-auto relative max-md:w-full max-md:px-4`}
          >
            <div
              onClick={() => setLoginOpen(false)}
              className="md:hidden absolute top-[15px] right-[15px] w-[30px] h-[30px] bg-[#ededed] rounded-full cursor-pointer flex justify-center items-center"
            >
              <AiOutlineClose size={20} className="text-[#8c8c8c]" />
            </div>
            <h1
              className={`${theme === "dark" && "text-DarkMainText"
                } text-center font-[700] text-[24px] max-md:text-[20px] leading-[32px]`}
            >
              {t("Log In or Register")}
            </h1>
            <form onSubmit={emailSubmit} className="flex flex-col gap-4">
              <input
                className={`${theme === "dark"
                    ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                    : "text-SecondText border-[#f2e9e9] hover:border-[#b5a7ac66] focus:border-SecondText"
                  } cursor-pointer w-full duration-300 h-[46px] rounded-[8px] text-[18px] max-md:text-[16px] font-[500] border-2 outline-none py-[21px] px-[19px] focus:ring-0`}
                type="text"
                placeholder={t("Please input your email .")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className={`${emailLoading
                    ? "opacity-30 cursor-default pointer-events-none"
                    : ""
                  } flex justify-center items-center h-[46px] w-full min-w-[170px] text-[18px] max-md:text-[16px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300`}
                type="submit"
              >
                {emailLoading ? (
                  <ProcessSpinner />
                ) : (
                  <div className="w-full ">{t("Continue")}</div>
                )}
              </button>
            </form>
            {/* <div className="relative flex my-[25px]">
                <div className="mr-[25px] ml-auto w-[134px] mt-[2%] h-[1px] bg-[#ebe7e7]"></div>
                <h1
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } font-[400] text-[14px] leading-[14px]`}
                >
                  {t("or")}
                </h1>
                <div className="ml-[25px] mr-auto w-[134px] mt-[2%] h-[1px] bg-[#ebe7e7]"></div>
              </div> */}
            {/* <div className="flex flex-col gap-[10px] mb-[40px]">
                <button
                  onClick={() => {
                    handleGoogleLogin();
                  }}
                  className={`${
                    theme === "dark"
                      ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                      : "bg-white border-[#ebe7e7] text-SecondText hover:bg-[#fbf9f9]"
                  } relative w-full flex justify-center items-center h-[50px] rounded-[8px] cursor-pointer border duration-300`}
                >
                  {loading ? (
                    <GoogleSpinner />
                  ) : (
                    <>
                      <div className="absolute left-[16px] w-[16px] h-[16px]">
                        {logGoogle}
                      </div>
                      <h1 className="font-[700] text-[16px]">
                        {t("sign in with Google")}
                      </h1>
                    </>
                  )}
                </button>
              </div> */}
            <div
              className={`${theme === "dark" ? "text-DarkMainText/70" : "text-[#958289]"
                } max-w-full h-[34px] w-fit mx-auto text-center font-[400] text-[13px] leading-[16.9px]`}
            >
              {t("By registering, you consent to our")}
              <Link
                to="/terms-of-service"
                className={`${theme === "dark" ? "text-DarkMainText" : "text-SecondText"
                  } inline-block underline mx-1`}
              >
                {t("Terms of Service")}
              </Link>
            </div>
          </div>
        }
        overLayClassName="z-40"
        className={`${theme === "dark"
            ? "bg-DarkMainBg text-DarkMainText"
            : "text-MainText bg-MainBg"
          } z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed rounded-[20px] max-md:rounded-none max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 max-md:m-0 max-md:h-screen max-md:w-full max-md:overflow-y-hidden`}
        onClose={() => setLoginOpen(false)}
      />
      <Popup
        toggleSwitch={codeOpen}
        children={
          <div
            className={`${theme === "dark"
                ? "bg-DarkMainBg text-DarkMainText"
                : "text-MainText bg-MainBg"
              } rounded-[20px] max-md:rounded-none p-[36px] md:w-[450px] flex flex-col gap-4 max-md:w-full m-auto relative overflow-hidden`}
          >
            <h1
              onClick={() => {
                setCodeOpen(false);
                setLoginOpen(true);
              }}
              className={`${theme === "dark" ? "text-DarkMainText/70" : "text-[#958289]"
                } underline text-center font-[400] text-[13px] leading-[16.9px] cursor-pointer`}
            >
              {t("Go back")}
            </h1>
            <h1
              className={`${theme === "dark" && "text-DarkMainText"
                } text-center font-[700] text-[24px] max-md:text-[20px] leading-[28px]`}
            >
              {t("Verification code has been sent to your email")}
            </h1>
            <div
              className={`${theme === "dark" ? "text-DarkMainText" : "text-MainText"
                } mx-auto text-center font-[400] text-[15px] leading-[19px]`}
            >
              <div className="inline">
                {t("An email containing the code has been dispatched to you:")}
              </div>{" "}
              <h1 className="inline">{email}</h1>
            </div>
            <form onSubmit={codeSubmit} className="flex flex-col gap-4">
              <input
                className={`${theme === "dark"
                    ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                    : "text-SecondText border-[#f2e9e9] hover:border-[#b5a7ac66] focus:border-SecondText"
                  } cursor-pointer w-full duration-300 h-[46px] rounded-[8px] text-[18px] max-md:text-[16px] font-[500] border-2 outline-none py-[21px] px-[19px] focus:ring-0`}
                type="number"
                placeholder={t("Please input the code")}
                pattern="\d{4}"
                inputMode="numeric"
                maxLength={4}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <button
                className={`${codeLoading
                    ? "opacity-30 cursor-default pointer-events-none"
                    : ""
                  } flex justify-center items-center h-[46px] w-full min-w-[170px] text-[18px] max-md:text-[16px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300`}
                type="submit"
              >
                {codeLoading ? (
                  <ProcessSpinner />
                ) : (
                  <div className="w-full ">{t("Continue")}</div>
                )}
              </button>
            </form>
            <h1
              onClick={resendCode}
              className={`${theme === "dark" ? "text-DarkMainText/70" : "text-[#958289]"
                } ${!showResendButton && "invisible pointer-events-none"
                } underline text-center font-[400] text-[13px] leading-[16.9px] cursor-pointer`}
            >
              {t("Send Code Again")}
            </h1>
          </div>
        }
        overLayClassName="z-40"
        className={` ${theme === "dark"
            ? "bg-DarkMainBg text-DarkMainText"
            : "text-MainText bg-MainBg"
          } z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed rounded-[20px] max-md:rounded-none max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 max-md:m-0 max-md:h-screen max-md:w-full max-md:overflow-y-hidden`}
        onClose={() => setCodeOpen(false)}
      />
    </>
  );
}

export default LogInWithCode;
