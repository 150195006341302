import React from "react";

function Container({ children, className }) {
  return (
    <div
      className={`${className} container mx-auto w-[75%] max-xl:w-[80%] max-lg:w-[85%] max-md:w-[90%] max-sm:w-[95%]`}
    >
      {children}
    </div>
  );
}

export default Container;
