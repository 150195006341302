import { apiClient } from "./api";

export const fetchReviews = async () => {
  try {
    const response = await apiClient.get(`/api/ReviewOrder`);
    return response.data;
  } catch (error) {
    console.error("fetch reviews err : ", error);
  }
};

export const fetchAds = async () => {
  try {
    const response = await apiClient.get(`/api/ads`);
    return response.data;
  } catch (error) {
    console.error("fetch ads err : ", error);
  }
};
