import React, { useContext, useEffect, useState } from "react";
import CheckoutForm from "../Components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PhotoContext } from "../../Context/PhotoContext";
import Loading from "../../Shared/Components/Loading";
import { paymentIntent } from "../../Api/Paymentdetails";
import { useParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function PaymentForm() {
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  const { orderNumber } = useParams();
  const { loading, setLoading } = useContext(PhotoContext);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const paymentCLSC = async () => {
    setLoading(true);
    try {
      const response = await paymentIntent(orderNumber);
      // console.log(response);
      setClientSecret(response.client_secret);
      localStorage.setItem("paymentId", response.paymentIntent_Id);
      setPaymentId(response.paymentIntent_Id);

      const stripe = await stripePromise;

      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: 1000, // Replace this with your total amount
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check if the Payment Request is available
      pr.canMakePayment().then((result) => {
        if (result) {
          // console.log("Apple Pay or Google Pay is available:", result);
          setPaymentRequest(pr);
          setCanMakePayment(true); // Show the button
        } else {
          // console.log("Apple Pay or Google Pay is not available on this device.");
        }
      });
    } catch (error) {
      console.error("Failed to fetch intent:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    paymentCLSC();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    clientSecret && (
      <Elements stripe={stripePromise} options={options}>
        {/* {canMakePayment && paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )} */}
        <CheckoutForm />
      </Elements>
    )
  );
}

export default PaymentForm;
