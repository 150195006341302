import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import ProcessOfAddPhoto from "../Components/ProcessOfAddPhoto";
import { HiPlus } from "react-icons/hi";
import { PopupContext } from "../../Context/PopupContext";
import { PhotoContext } from "../../Context/PhotoContext";
import { Link } from "react-router-dom";
import {
  materialIcon,
  sizeIcon,
  effectIcon,
  matIcon,
} from "../../assets/imgs/SvgImgs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Popup from "../../Shared/Components/Popup";
import MaterialList from "../Components/MaterialList";
import SizeList from "../Components/SizeList";
import EffectList from "../Components/EffectList";
import MatList from "../Components/MatList";
import Loading from "../../Shared/Components/Loading";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { FaExclamation } from "react-icons/fa6";
import { fetchEditPhotos } from "../../Api/Editphotos";

function EditPhotos() {
  const userId = localStorage.getItem("userId");
  const [activePopup, setActivePopup] = useState(null);
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { setUploadSourceOpen } = useContext(PopupContext);
  const {
    loading,
    setLoading,
    updatePhotoArray,
    setUpdateTotalPrice,
    setSelectedMaterial,
    setSelectedSize,
    setSelectedEffect,
    setSelectedMat,
    fetchMaterialsData,
    fetchSizesData,
    fetchEffectsData,
    fetchMatsData,
    currentMaterial,
  } = useContext(PhotoContext);

  const lng = localStorage.getItem("vividVisionsLang") || "English";

  const [photosArray, setPhotoArray] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const getPhotosData = async () => {
    try {
      initialLoading && setLoading(true);
      const response = await fetchEditPhotos();
      setPhotoArray(response.data);
      if (response.data && response.data.length === 1) {
        setSelectedMaterial(response?.data[0]?.material_id);
        setSelectedSize(response?.data[0]?.size_id);
        setSelectedEffect(response?.data[0]?.effect_id);
        setSelectedMat(response?.data[0]?.mat_id);
      }
      if (response.success === "true") setInitialLoading(false);
    } catch (err) {
      console.error("get edit photos err : ", err);
    } finally {
      setLoading(false);
    }
  };
  const handleOpenPopup = (popupKey) => {
    if (activePopup === popupKey) {
      setActivePopup(null); // Toggle functionality: Click again to close the same popup
    } else {
      setActivePopup(popupKey);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMaterialsData();
    fetchEffectsData();
    window.pintrk &&
      window.pintrk("track", "editPagevisit", {
        event_id: "eventId0001",
        em: userId,
      });
  }, []);
  useEffect(() => {
    fetchSizesData();
    fetchMatsData();
  }, [currentMaterial]);

  useEffect(() => {
    getPhotosData();
    setUpdateTotalPrice((prev) => prev + 1);
  }, [updatePhotoArray]);

  useEffect(() => {
    // console.log("current material is : ", currentMaterial);
  }, [currentMaterial]);

  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkMainBg"
      } overflow-hidden min-h-screen`}
    >
      <Navbar sticky={true} />
      <ProcessOfAddPhoto />
      {loading ? (
        <Loading />
      ) : photosArray && photosArray.length === 0 ? (
        <div className="min-h-screen relative">
          <div
            className={`absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]`}
          >
            <div
              onClick={() => setUploadSourceOpen(true)}
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg shadow-registerWayBoxShadowDM"
                  : "shadow-xl bg-white"
              } addFlashing cursor-pointer rounded-full w-[170px] h-[170px] flex justify-center items-center mx-auto`}
            >
              <HiPlus size={90} />
            </div>
            <h1
              className={`${
                theme === "dark" ? "text-DarkMainText/70" : "text-[#b5a7ac]"
              } text-center mt-6 text-[21px] font-bold`}
            >
              {t("Begin Crafting")}
            </h1>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center sm:min-h-[75vh] md:min-h-[85vh] relative">
          {photosArray && photosArray.length > 0 && (
            <div
              className={`absolute left-2 z-10 top-[50%] -translate-y-[50%] max-md:hidden w-fit editPhotoBoxShadow`}
            >
              <div
                className={`${
                  theme === "dark"
                    ? "bg-DarkMainBg text-DarkMainText"
                    : "bg-white border-[#f2e9e9]"
                } flex flex-col items-center rounded-[10px] border py-[12px] px-[8px]`}
              >
                <div
                  className={`flex flex-col items-center justify-between border-b border-[#f2e9e9] pb-3 mb-3`}
                >
                  <div
                    onClick={() => {
                      handleOpenPopup("material");
                    }}
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText hover:bg-white/20"
                        : "text-MainText hover:bg-[#fbf6f6]"
                    } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
                  >
                    <div
                      className={`${
                        theme === "dark" ? "text-DarkMainText" : "text-MainText"
                      } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                    >
                      {materialIcon}
                    </div>
                    <h1 className="text-[13px] font-[600]">{t("Material")}</h1>
                  </div>
                  <div
                    onClick={() => {
                      handleOpenPopup("size");
                      // console.log("sizes :", sizesFromAPI);
                    }}
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText hover:bg-white/20"
                        : "text-MainText hover:bg-[#fbf6f6]"
                    } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
                  >
                    <div
                      className={`${
                        theme === "dark" ? "text-DarkMainText" : "text-MainText"
                      } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                    >
                      {sizeIcon}
                    </div>
                    <h1 className="text-[13px] font-[600]">{t("Size")}</h1>
                  </div>
                  <div
                    onClick={() => handleOpenPopup("effect")}
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText hover:bg-white/20"
                        : "text-MainText hover:bg-[#fbf6f6]"
                    } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
                  >
                    <div
                      className={`${
                        theme === "dark" ? "text-DarkMainText" : "text-MainText"
                      } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                    >
                      {effectIcon}
                    </div>
                    <h1 className="text-[13px] font-[600]">{t("Impact")}</h1>
                  </div>
                  <div
                    onClick={() => handleOpenPopup("mat")}
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText hover:bg-white/20"
                        : "text-MainText hover:bg-[#fbf6f6]"
                    } ${
                      (parseFloat(currentMaterial) === 5 ||
                        parseFloat(currentMaterial) === 1) &&
                      "hidden"
                    } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
                  >
                    <div
                      className={`${
                        theme === "dark" ? "text-DarkMainText" : "text-MainText"
                      } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                    >
                      {matIcon}
                    </div>
                    <h1 className="text-[13px] font-[600]">{t("Mat")}</h1>
                  </div>
                </div>
                <div
                  onClick={() => setUploadSourceOpen(true)}
                  className="mx-[8px] w-[47px] h-[47px] bg-[#639af938] rounded-full flex justify-center items-center hover:brightness-75 cursor-pointer duration-300"
                >
                  <HiPlus size={22} className="text-PrimaryColor" />
                </div>
              </div>
            </div>
          )}
          <Slider
            className={`${
              photosArray.length > 1 && "md:pl-5"
            } w-full editPhotosSlider mx-auto`}
            slidesToScroll={1}
            infinite={false}
            speed={500}
            dots={true}
            arrows={false}
            responsive={[
              {
                breakpoint: 3500,
                settings: {
                  slidesToShow:
                    photosArray.length > 3 ? 4 : photosArray.length > 2 ? 3 : 2,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 1800,
                settings: {
                  slidesToShow: photosArray.length > 2 ? 3 : 2,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 1300,
                settings: {
                  slidesToShow: photosArray.length > 1 ? 2 : 1,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: photosArray.length > 1 ? 1.5 : 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1.3,
                  slidesToScroll: 1,
                  arrows: "true",
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: "true",
                },
              },
            ]}
          >
            {loading ? (
              <Loading />
            ) : (
              photosArray &&
              photosArray.map((photo) => {
                // console.log(`photo with id ${photo.id} :`, photo);
                const size = `size${photo.size.length_cm}x${photo.size.width_cm}`;
                // console.log("size is : ", size);
                return (
                  <>
                    <Link
                      to={`/edit-photos/${photo.id}`}
                      key={photo.id}
                      className={`${
                        photo?.material?.name === "Canvas"
                          ? "bg-transparent hover:brightness-100 cursor-auto"
                          : ""
                      } h-screen cursor-pointer hover:brightness-75 duration-300`}
                    >
                      <div
                        className={`${
                          photo?.material?.name === "Black Frame"
                            ? "border-black border-[7px]"
                            : ""
                        } ${
                          photo?.material?.name === "White Frame"
                            ? "border-white border-[7px]"
                            : ""
                        } ${
                          photo?.material?.name === "Espresso Frame"
                            ? "border-[#612718] border-[7px]"
                            : ""
                        } ${
                          photo?.material?.name === "Canvas"
                            ? "border-0 bg-transparent shadow-framePhotoBoxShadow rounded-tr-[25px] rounded-bl-[25px]"
                            : ""
                        } ${
                          size === "size61x61"
                            ? "w-[380px] h-[380px] max-vsm:w-[250px] max-vsm:h-[250px]"
                            : ""
                        } ${
                          size === "size36x36"
                            ? "w-[320px] h-[320px] max-vsm:w-[200px] max-vsm:h-[200px]"
                            : ""
                        } ${
                          size === "size31x23"
                            ? "w-[300px] h-[280px] max-vsm:w-[180px] max-vsm:h-[150px]"
                            : ""
                        } ${
                          size === "size41x31"
                            ? "w-[340px] h-[300px] max-vsm:w-[210px] max-vsm:h-[180px]"
                            : ""
                        } ${
                          size === "size61x46"
                            ? "w-[380px] h-[350px] max-vsm:w-[250px] max-vsm:h-[225px]"
                            : ""
                        } ${
                          size === "size46x61"
                            ? "w-[350px] h-[380px] max-vsm:w-[225px] max-vsm:h-[250px]"
                            : ""
                        } ${
                          size === "size76x51"
                            ? "w-[400px] h-[360px] max-vsm:w-[275px] max-vsm:h-[240px]"
                            : ""
                        } ${
                          size === "size91x61"
                            ? "w-[420px] h-[380px] max-vsm:w-[300px] max-vsm:h-[250px]"
                            : ""
                        } ${
                          size === "size101x71"
                            ? "w-[430px] h-[390px] max-vsm:w-[310px] max-vsm:h-[260px]"
                            : ""
                        } ${
                          size === "size51x76"
                            ? "w-[360px] h-[400px] max-vsm:w-[240px] max-vsm:h-[275px]"
                            : ""
                        } ${
                          size === "size61x91"
                            ? "w-[380px] h-[420px] max-vsm:w-[250px] max-vsm:h-[300px]"
                            : ""
                        } ${
                          size === "size71x101"
                            ? "w-[390px] h-[430px] max-vsm:w-[260px] max-vsm:h-[310px]"
                            : ""
                        } ${
                          size === "size31x41"
                            ? "w-[300px] h-[340px] max-vsm:w-[180px] max-vsm:h-[210px]"
                            : ""
                        } ${
                          size === "size23x31"
                            ? "w-[280px] h-[300px] max-vsm:w-[150px] max-vsm:h-[180px]"
                            : ""
                        } ${
                          theme === "dark"
                            ? "shadow-framePhotoBoxShadowDM"
                            : "shadow-framePhotoBoxShadow"
                        } image_frame duration-300 relative cursor-pointer`}
                      >
                        <div
                          className={`
                      ${
                        photo?.material?.name === "Black Frame"
                          ? "bg-[#4d4d4d]"
                          : ""
                      } ${
                            photo?.material?.name === "White Frame"
                              ? "bg-[#ecebe9]"
                              : ""
                          } ${
                            photo?.material?.name === "Espresso Frame"
                              ? "bg-[#612718]"
                              : ""
                          } ${
                            photo?.material?.name === "Canvas" ||
                            photo?.material?.name === "Poster"
                              ? "hidden"
                              : ""
                          }  ${
                            size === "size61x61"
                              ? "h-[391.6px] max-vsm:h-[262px]"
                              : ""
                          } ${
                            size === "size36x36"
                              ? "h-[332px] max-vsm:h-[211px]"
                              : ""
                          } ${
                            size === "size31x23"
                              ? "h-[291.1px] max-vsm:h-[161.5px]"
                              : ""
                          } ${
                            size === "size41x31"
                              ? "h-[311.9px] max-vsm:h-[192px]"
                              : ""
                          } ${
                            size === "size61x46"
                              ? "h-[361.9px] max-vsm:h-[237.1px]"
                              : ""
                          } ${
                            size === "size46x61"
                              ? "h-[391.5px] max-vsm:h-[262px]"
                              : ""
                          } ${
                            size === "size76x51"
                              ? "h-[371px] max-vsm:h-[252px]"
                              : ""
                          } ${
                            size === "size91x61"
                              ? "h-[392px] max-vsm:h-[262px]"
                              : ""
                          } ${
                            size === "size101x71"
                              ? "h-[392px] max-vsm:h-[262px]"
                              : ""
                          } ${
                            size === "size51x76"
                              ? "h-[411.6px] max-vsm:h-[286.9px]"
                              : ""
                          } ${
                            size === "size61x91"
                              ? "h-[431.8px] max-vsm:h-[312px]"
                              : ""
                          } ${
                            size === "size71x101"
                              ? "h-[431.6px] max-vsm:h-[312px]"
                              : ""
                          } ${
                            size === "size31x41"
                              ? "h-[351.5px] max-vsm:h-[222px]"
                              : ""
                          } ${
                            size === "size23x31"
                              ? "h-[311.8px] max-vsm:h-[192px]"
                              : ""
                          } w-[8.84211px] -top-[6.3px] -right-[15.5px] absolute rightClipPath cursor-pointer`}
                        ></div>
                        <div
                          className={`${
                            photo?.material?.name === "Black Frame"
                              ? "bg-[#333333]"
                              : ""
                          } ${
                            photo?.material?.name === "White Frame"
                              ? "bg-[#dfdedd]"
                              : ""
                          } ${
                            photo?.material?.name === "Espresso Frame"
                              ? "bg-[#5b2416]"
                              : ""
                          } ${
                            photo?.material?.name === "Canvas" ||
                            photo?.material?.name === "Poster"
                              ? "hidden"
                              : ""
                          } ${
                            size === "size61x61"
                              ? "w-[389.5px] max-vsm:w-[259.6px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size36x36"
                              ? "w-[329.7px] max-vsm:w-[210px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size31x23"
                              ? "w-[310px] max-vsm:w-[190px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size41x31"
                              ? "w-[350px] max-vsm:w-[220px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size61x46"
                              ? "w-[389.5px] max-vsm:w-[258px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size46x61"
                              ? "w-[359.5px] max-vsm:w-[231px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size76x51"
                              ? "w-[410px] max-vsm:w-[285px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size91x61"
                              ? "w-[429.5px] max-vsm:w-[309.6px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size51x76"
                              ? "w-[369.5px] max-vsm:w-[249px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size61x91"
                              ? "w-[389.2px] max-vsm:w-[259.6px] bottomClipPath2 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size31x41"
                              ? "w-[310px] max-vsm:w-[190px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                              : ""
                          } ${
                            size === "size23x31"
                              ? "w-[289px] max-vsm:w-[159.6px] bottomClipPath1 max-vsm:bottomClipPathMobile"
                              : ""
                          } h-[11.7895px] -bottom-[18.4px] -left-[6.8px] absolute cursor-pointer`}
                        ></div>
                        <div
                          className={`${
                            photo?.material?.name === "Black Frame"
                              ? "bg-transparent"
                              : ""
                          } ${
                            photo?.material?.name !== "Canvas" &&
                            photo?.material?.name !== "Poster" &&
                            photo?.mat?.name === "mat1"
                              ? "p-[0px]"
                              : ""
                          } ${
                            photo?.material?.name !== "Canvas" &&
                            photo?.material?.name !== "Poster" &&
                            photo?.mat?.name === "mat2"
                              ? "p-[10px]"
                              : ""
                          } ${
                            photo?.material?.name !== "Canvas" &&
                            photo?.material?.name !== "Poster" &&
                            photo?.mat?.name === "mat3"
                              ? "p-[20px]"
                              : ""
                          } ${
                            photo?.material?.name !== "Canvas" &&
                            photo?.material?.name !== "Poster" &&
                            photo?.mat?.name === "mat4"
                              ? "p-[40px]"
                              : ""
                          } relative w-full h-full cursor-pointer`}
                        >
                          <div className="w-full h-full relative">
                            {photo?.picture_size_mb < 0.2 &&
                              !(
                                size === "size23x31" ||
                                size === "size31x23" ||
                                size === "size31x41" ||
                                size === "size41x31" ||
                                size === "size36x36"
                              ) && (
                                <div
                                  className={`${
                                    theme === "dark"
                                      ? "bg-black/50"
                                      : "bg-white/50"
                                  } z-10 rounded-full p-2 flex items-center justify-center absolute top-2 left-2 text-[14px]`}
                                >
                                  <FaExclamation />
                                </div>
                              )}
                            <img
                              className={`${
                                photo?.material?.name === "Canvas"
                                  ? "rounded-tr-[25px] rounded-bl-[25px] shadow-2xl"
                                  : ""
                              } ${
                                photo?.effect?.name === "silver" ? "silver" : ""
                              } ${
                                photo?.effect?.name === "noir" ? "noir" : ""
                              } ${
                                photo?.effect?.name === "vivid" ? "vivid" : ""
                              } ${
                                photo?.effect?.name === "dramatic"
                                  ? "dramatic"
                                  : ""
                              } image_element`}
                              src={photo.url_photo}
                              alt={`Preview ${photo?.id}`}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })
            )}
          </Slider>
        </div>
      )}

      {photosArray && photosArray.length > 0 && (
        <div className="md:hidden fixed bottom-[40px] left-[50%] -translate-x-[50%] w-fit editPhotoBoxShadow">
          <div
            className={`${
              theme === "dark"
                ? "bg-DarkMainBg text-DarkMainText"
                : "bg-white border-[#f2e9e9]"
            } flex items-center rounded-[10px] border py-[12px] px-[8px]`}
          >
            <div
              className={`flex items-center justify-between ${
                lng === "Arabic" ? "border-l" : "border-r"
              } border-[#f2e9e9]`}
            >
              <div
                onClick={() => {
                  handleOpenPopup("material");
                }}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
              >
                <div
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                >
                  {materialIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Material")}</h1>
              </div>
              <div
                onClick={() => {
                  handleOpenPopup("size");
                }}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
              >
                <div
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                >
                  {sizeIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Size")}</h1>
              </div>
              <div
                onClick={() => handleOpenPopup("effect")}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
              >
                <div
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                >
                  {effectIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Impact")}</h1>
              </div>
              <div
                onClick={() => handleOpenPopup("mat")}
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:bg-white/20"
                    : "text-MainText hover:bg-[#fbf6f6]"
                } ${
                  (parseFloat(currentMaterial) === 5 ||
                    parseFloat(currentMaterial) === 1) &&
                  "hidden"
                } flex flex-col w-[58px] gap-[4px] cursor-pointer hover:rounded-[5px] text-center duration-300`}
              >
                <div
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } w-[32px] h-[32px] mx-auto flex justify-center items-center`}
                >
                  {matIcon}
                </div>
                <h1 className="text-[13px] font-[600]">{t("Mat")}</h1>
              </div>
            </div>
            <div
              onClick={() => setUploadSourceOpen(true)}
              className=" mx-[8px] w-[47px] h-[47px] bg-[#639af938] rounded-full flex justify-center items-center hover:brightness-75 cursor-pointer duration-300"
            >
              <HiPlus size={22} className="text-PrimaryColor" />
            </div>
          </div>
        </div>
      )}
      <div>
        <Popup
          toggleSwitch={activePopup === "material"}
          children={<MaterialList handleOpenPopup={handleOpenPopup} />}
          overLayClassName="bg-transparent z-20"
          className={`${
            activePopup === "material" ? "bottom-[14px]" : "bottom-full"
          } fixed z-50 rounded-[10px] photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-w-[530px] max-sm:w-[90%] duration-300`}
          onClose={() => setActivePopup(null)}
        />
        <Popup
          toggleSwitch={activePopup === "size"}
          children={<SizeList handleOpenPopup={handleOpenPopup} />}
          overLayClassName="bg-transparent z-20"
          className={`${
            activePopup === "size" ? "bottom-[14px]" : "bottom-full"
          } fixed z-50 rounded-[10px] photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-vsm:w-[90%] duration-300`}
          onClose={() => setActivePopup(null)}
        />
        <Popup
          toggleSwitch={activePopup === "effect"}
          children={<EffectList handleOpenPopup={handleOpenPopup} />}
          overLayClassName="bg-transparent z-20"
          className={`${
            activePopup === "effect" ? "bottom-[14px]" : "bottom-full"
          } fixed z-50 rounded-[10px] photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-sm:w-[90%] duration-300`}
          onClose={() => setActivePopup(null)}
        />
        <Popup
          toggleSwitch={activePopup === "mat"}
          children={<MatList handleOpenPopup={handleOpenPopup} />}
          overLayClassName="bg-transparent z-20"
          className={`${
            activePopup === "mat" ? "bottom-[14px]" : "bottom-full"
          } fixed z-50 rounded-[10px] photoSelectMenuBoxShadow left-[50%] -translate-x-[50%] max-sm:w-[90%] duration-300`}
          onClose={() => setActivePopup(null)}
        />
      </div>
    </div>
  );
}

export default EditPhotos;
