import React, { useEffect, useState } from "react";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { useTheme } from "../../Context/ThemeContext";
import { postAds } from "../../Api/Dashboard";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toastSuccess } from "../../Shared/Components/ToastNotification/Toast";
import { fetchAds } from "../../Api/Home";
import Loading from "../../Shared/Components/Loading";

function Ads() {
  const { theme } = useTheme();
  const [data, setData] = useState();
  const [updateData, setUpdateData] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const getAdData = async () => {
    try {
      setDataLoading(true);
      const response = await fetchAds();
      // console.log(response);
      setData(response.data);
    } catch (err) {
      console.error("get ads data err : ", err);
    } finally {
      setDataLoading(false);
    }
  };
  const [loading, setLoading] = useState(false);
  const handleChange = (index, field, value) => {
    const updatedData = data.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await postAds(data);
      // console.log(response);
      if (response.success === "true") {
        toastSuccess(response.message);
        setUpdateData((prev) => prev + 1);
      }
    } catch (err) {
      console.error("handle edit on ads err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAdData();
  }, [updateData]);
  useEffect(() => {
    // console.log(data);
  }, [data]);

  return dataLoading ? (
    <Loading />
  ) : (
    <div>
      <h1 className="text-2xl font-semibold">VividVisions Advertisments</h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3 mt-4">
        {data &&
          data.map((item, index) => (
            <div key={item.id}>
              <div className="grid grid-cols-2 gap-3">
                {item.title_en !== "0" && (
                  <div className="flex flex-col gap-2">
                    <label htmlFor={`enTitle${item.id}`}>English Title</label>
                    <textarea
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[80px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
                      id={`enTitle${item.id}`}
                      value={item.title_en}
                      onChange={(e) =>
                        handleChange(index, "title_en", e.target.value)
                      }
                    ></textarea>
                  </div>
                )}
                {item.title_gr !== "0" && (
                  <div className="flex flex-col gap-2">
                    <label htmlFor={`grTitle${item.id}`}>German Title</label>
                    <textarea
                      className={`${
                        theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText"
                      } p-[0.75rem] min-h-[80px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
                      id={`grTitle${item.id}`}
                      value={item.title_gr}
                      onChange={(e) =>
                        handleChange(index, "title_gr", e.target.value)
                      }
                    ></textarea>
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  <label htmlFor={`enDescription${item.id}`}>
                    English Description
                  </label>
                  <textarea
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                        : "bg-white border-[#e6e6e6] focus:border-SecondText"
                    } p-[0.75rem] min-h-[80px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
                    id={`enDescription${item.id}`}
                    value={item.description_en}
                    onChange={(e) =>
                      handleChange(index, "description_en", e.target.value)
                    }
                  ></textarea>
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor={`enDescription${item.id}`}>
                    German Description
                  </label>
                  <textarea
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText"
                        : "bg-white border-[#e6e6e6] focus:border-SecondText"
                    } p-[0.75rem] min-h-[80px] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none duration-300 focus:ring-0`}
                    id={`enDescription${item.id}`}
                    value={item.description_gr}
                    onChange={(e) =>
                      handleChange(index, "description_gr", e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
              <BreakDiv
                className={`${index + 1 === data.length && "hidden"} my-2`}
              />
            </div>
          ))}
        <button
          className={`${
            loading ? "cursor-default opacity-75" : "cursor-pointer"
          } flex justify-center items-center bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 rounded-[5px] text-white font-semibold w-fit py-1 px-2 min-w-[70px] min-h-[40px]`}
          type="submit"
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            "Save"
          )}
        </button>
      </form>
    </div>
  );
}

export default Ads;
