

export const trackGoogleAds = ({ value, currency, send_to }) => {
    if (window.gtag) {
        window.gtag('event', 'conversion', {
            'send_to': send_to,
            'value': value,
            'currency': currency
        });
        // console.log('Google gtag is  initialized');
    } else {
        console.error('Google gtag is not initialized');
    }
};