import { toastError } from "../Shared/Components/ToastNotification/Toast";
import { apiClient } from "./api";

export const fetchCategories = async () => {
  try {
    const response = await apiClient.get(`/api/Category`);
    return response.data.data;
  } catch (error) {
    console.error("fetch category err", error);
  }
};

export const fetchPhotosByCategoryId = async (categoryId) => {
  try {
    const response = await apiClient.get(
      `/api/PictureByCategory?category_id=${categoryId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("fetch photos by category", error);
  }
};

export const fetchPhotos = async (
  statusId,
  pictureId = "",
  categoryId = "",
  page = 1
) => {
  try {
    const response = await apiClient.get(
      `/api/pictureArtCollection?status_id=${statusId}&picture_id=${pictureId}&category_id=${categoryId}&page=${page}`
    );
    return statusId === 1 
      ? response.data : response.data.data;
  } catch (error) {
    console.error("fetch photos err", error);
  }
};

export const fetchReviewsForPhoto = async (page = 1) => {
  try {
    const response = await apiClient.get(
      `/api/getPictureEvaluationWithUser?page=${page}`
    );
    return response.data.data;
  } catch (error) {
    console.error("fetch reviews for photo err", error);
  }
};

export const photoRequest = async (data) => {
  try {
    const response = await apiClient.post(
      `/api/NotifyRequest`,data
    );
    return response.data;
  } catch (error) {
    console.error("photo request err : ", error);
  }
};

export const fetchMaterialsPicture = async (id) => {
  try {
    const response = await apiClient.get(`/api/getMaterialPicture`);
    return response.data.data;
  } catch (error) {
    console.error("fetch materials for picture err : ", error);
  }
};

export const fetchRandomPictures = async (id,page = 1) => {
  try {
    const response = await apiClient.get(`/api/PictureRandom?picture_id=${id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.error("fetch random pictures err : ", error);
  }
};

export const fetchSizesByPictureId = async (material_id,picture_id) => {
  try {
    const response = await apiClient.get(`/api/PictureSizeByMaterial?id=${material_id}&picture_id=${picture_id}`);
    return response.data;
  } catch (error) {
    console.error("fetch sizes by picture id err", error);
  }
};

export const updatePriceBasedOnMatAndSize = async (picture_id,material_id,size_id) => {
  try {
    const response = await apiClient.get(`/api/PicturePrice?picture_id=${picture_id}&material_id=${material_id}&size_id=${size_id}`);
    return response.data;
  } catch (error) {
    console.error("update price based on material and size err : ", error);
  }
};

export const addPictureToCart = async (data) => {
  try {
    const response = await apiClient.post(`/api/addPictureToCart`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("add picture to cart err", error);
    toastError(error.response.data.errors)
  }
};
