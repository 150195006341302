import { useTranslation } from "react-i18next";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import React, { useContext } from "react";
import { useTheme } from "../../Context/ThemeContext";

function SelectSortList({ className }) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    setSelectedSortValue,
    setCurrentStatusId,
    setPageNumber,
    setPaginationKey,
    sortKey,
  } = useContext(PhotoContext);
  const { setSortListOpen } = useContext(PopupContext);

  // Define the sorting options
  const sortOptions = [
    { value: "Latest", status: 2 },
    { value: "Higher Price", status: 5 },
    { value: "Lower Price", status: 6 },
    // { value: "Higher Rating", status: 3 },
    // { value: "Lower Rating", status: 4 },
    { value: "Best Seller", status: 9 },
  ];

  return (
    <div
      key={sortKey}
      className={`${className} ${
        theme === "dark" ? "bg-DarkMainBg" : "bg-white"
      }`}
    >
      {sortOptions.map((option) => (
        <div
          key={option.value}
          onClick={() => {
            setPageNumber(1);
            setPaginationKey((prev) => prev + 1);
            setCurrentStatusId(option.status);
            setSelectedSortValue(option.value);
            setSortListOpen(false);
          }}
          className={`${
            theme === "dark"
              ? "bg-DarkMainBg hover:bg-HoverPrimaryColor"
              : "bg-gray-100 hover:bg-gray-200"
          } cursor-pointer px-2 py-1 hover:rounded-[6px] hover:shadow-lg duration-300`}
        >
          {t(option.value)}
        </div>
      ))}
    </div>
  );
}

export default SelectSortList;
