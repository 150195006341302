import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Loading() {
  const { theme } = useTheme();
  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkMainBg"
      } flex justify-center items-center h-screen max-h-screen`}
    >
      <AiOutlineLoading3Quarters
        size={30}
        className={`${
          theme === "dark" ? "text-DarkMainText" : "text-PrimaryColor"
        } animate-spin`}
      />
    </div>
  );
}

export default Loading;
