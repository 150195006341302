import React, { useEffect } from "react";
import ReviewsSection from "./Home/Sections/ReviewsSection";
import ReviewsList from "../Components/ReviewsList";
import Container from "../../Shared/Components/Container";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import BreakDiv from "../../Shared/Components/BreakDiv";

function Reviews() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar sticky={true} />
      <Container className="mt-5">
        <ReviewsSection />
        <ReviewsList />
        <BreakDiv className="my-[40px] max-md:hidden" />
        <Footer />
      </Container>
    </div>
  );
}

export default Reviews;
