import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function FAQsItem({ title, content }) {
  const { theme } = useTheme();
  return (
    <div>
      <h1 className="text-PrimaryColor text-[18px] leading-[23px] font-[700] mb-[8px]">
        {title}
      </h1>
      <h2
        className={`${
          theme === "dark" ? "text-DarkMainText/70" : "text-SecondText"
        } text-[16px] leading-[22px] mb-[36px]`}
      >
        {content}
      </h2>
    </div>
  );
}

export default FAQsItem;
