import React from "react";
import Skeleton from "react-loading-skeleton";

function OrderInformationSkeleton() {
  return (
    <div>
      <div className="w-full h-[60px] mb-5">
        <Skeleton width={`100%`} className="h-full rounded-xl" />
      </div>
      <div className="flex justify-between items-center mb-5">
        <div className="w-[250px] h-[60px]">
          <Skeleton width={`100%`} className="h-full rounded-xl" />
        </div>
        <div className="w-[200px] h-[60px]">
          <Skeleton width={`100%`} className="h-full rounded-xl" />
        </div>
      </div>
      <div className="w-full h-[220px] mb-5">
        <Skeleton width={`100%`} className="h-full rounded-xl" />
      </div>
      <div className="w-full h-[220px] mb-5">
        <Skeleton width={`100%`} className="h-full rounded-xl" />
      </div>
    </div>
  );
}

export default OrderInformationSkeleton;
