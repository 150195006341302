import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import ReviewItem from "../../../Components/ReviewItem";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../Context/ThemeContext";
import { fetchReviews } from "../../../../Api/Home";
import ReviewHomeSkeleton from "../../../Components/Skeleton/ReviewHomeSkeleton";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

function ReviewsSection({ homeMode }) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const getReviews = async () => {
    try {
      setLoading(true);
      const response = await fetchReviews();
      // console.log(response);
      setReviews(response.data);
    } catch (err) {
      console.error("fetch photo reviews err", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getReviews();
  }, []);
  return (
    <div
      className={`${theme === "dark" && "text-DarkMainText"
        } mx-auto max-md:mt-[40px] mb-[40px]`}
    >
      <div className="mb-[20px] text-center">
        <h1 className="font-bold text-3xl mb-3">
          {loading ? (
            <Skeleton width={100} className="h-[20px]" />
          ) : (
            t("Customer Reviews")
          )}
        </h1>
        <p className="text-lg font-semibold">
          {loading ? (
            <Skeleton width={250} className="h-[15px]" />
          ) : (
            t("Your feedback drives us – your happiness is our goal!")
          )}
        </p>
      </div>
      {loading ? (
        <div className="reviewSlider">
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            breakpoints={{
              1536: {
                slidesPerView: 4.5,
              },
              1280: {
                slidesPerView: 3.5,
              },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2.5,
              },
              0: {
                slidesPerView: 1.1,
              },
            }}
            navigation
            pagination={{ clickable: true }}
          >
            {Array.from({ length: 6 }).map((_, index) => (
              <SwiperSlide key={index}>
                <ReviewHomeSkeleton />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div style={{ direction: "ltr" }} className="reviewSlider">
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            breakpoints={{
              1536: {
                slidesPerView: 4.5,
              },
              1280: {
                slidesPerView: 3.5,
              },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2.5,
              },
              0: {
                slidesPerView: 1.1,
              },
            }}
            navigation
            pagination={{ clickable: true }}
          >
            {reviews &&
              reviews.map((review, index) => (
                <SwiperSlide key={index} className="min-h-[550px]">
                  <ReviewItem
                    image={review.url_image}
                    description={review.note}
                    pic={review.user.url_image}
                    name={review.user.name || "No Name Provided"}
                  // or this way
                  // name={review.order?.addresses?.full_name}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
      {homeMode &&
        (loading ? (
          <div className="w-full text-center">
            <Skeleton width={100} className="h-[15px]" />
          </div>
        ) : (
          <Link
            to={`/reviews`}
            className="w-fit block text-[18px] max-md:text-[16px] max-vsm:text-[14px] cursor-pointer underline hover:text-DarkMainBg duration-300 mx-auto"
          >
            {t("Show All Reviews")}
          </Link>
        ))}
    </div>
  );
}

export default ReviewsSection;
