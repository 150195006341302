import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "../../../Context/ThemeContext";

function ReviewHomeSkeleton() {
  const { theme } = useTheme();
  return (
    <div
      className={`${
        theme === "dark" ? "bg-DarkBoxBg" : "bg-white"
      } flex flex-col h-[550px] max-lg:h-[450px]`}
    >
      <div className="basis-[70%] max-md:max-h-[400px]">
        <Skeleton className="w-full h-full" />
      </div>
      <div className="py-[15px] px-[18px] h-[50%]">
        <Skeleton className="w-full h-[80%] rounded-xl" />
        <div className="flex items-center gap-2">
          <div className="rounded-full w-[50px] h-[50px] overflow-hidden">
            <Skeleton width={`100%`} className="h-full" />
          </div>
          <div
            className={`font-semibold text-[20px] max-md:text-[18px] max-vsm:text-[16px]`}
          >
            <Skeleton width={150} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewHomeSkeleton;
