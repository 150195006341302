import React, { useContext, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { SlBasket } from "react-icons/sl";
import { MdLightMode, MdDarkMode } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar";
import Checkout from "../Components/Checkout";
import SelectLanguage from "../Components/SelectLanguage";
import PromoCode from "../Components/PromoCode";
import InputPromoCode from "../Components/InputPromoCode";
import FAQs from "../Components/FAQs";
import email from "../../assets/imgs/SocialMediaLogo/em.webp";
import logo from "../../assets/imgs/Logo/logo.png";
import logoDM from "../../assets/imgs/Logo/darkModeLogo.png";
import { showTotalCartPrice } from "../../Api/Editphotos";
import Cookies from "js-cookie";
import { useTheme } from "../../Context/ThemeContext";
import LogInWithCode from "./LogInWithCode";
import { FaUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import ProfileList from "./ProfileList";
import Popup from "../../Shared/Components/Popup";
import americaFlag from "../../assets/imgs/americaFlag.png";
import germanyFlag from "../../assets/imgs/germanyFlag.png";
import saudiArabiaFlag from "../../assets/imgs/saudiArabiaFlag.png";
import Container from "../../Shared/Components/Container";
import Promo from "./Promo";

function Navbar({
  sticky,
  backArrow,
  backLink,
  hideSidebar,
  hideLapNavItems,
  showDMIcons,
  className,
  hideBasket,
}) {
  const { theme, setTheme } = useTheme();
  const userToken = Cookies.get("userToken");
  const curiousToken = Cookies.get("curiousToken");
  const currency = localStorage.getItem("currency") || "EUR";
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const { totalPhotos, logNavigation } = useContext(PhotoContext);
  const { t } = useTranslation();

  const {
    profileListOpen,
    setProfileListOpen,
    sideBarOpen,
    setSideBarOpen,
    setLanguageOpen,
    setCheckoutOpen,
    checkoutOpen,
    setLoginOpen,
  } = useContext(PopupContext);
  const {
    totalPrice,
    setTotalPrice,
    setTotalPhotos,
    updateTotalPrice,
    priceLoading,
    setPriceLoading,
  } = useContext(PhotoContext);
  useEffect(() => {
    const showPrice = async () => {
      try {
        const response = await showTotalCartPrice();
        // console.log(response.data);
        setTotalPrice(response.data?.totalCost);
        setTotalPhotos(response.data?.cartItemsCount);
        setPriceLoading(false);
      } catch (err) {
        console.error("show total price in cart err", err);
      }
    };
    (curiousToken || userToken) && showPrice();
  }, [updateTotalPrice]);
  return (
    <>
      <div
        className={`${sticky ? "sticky" : "fixed"} ${className} ${
          theme === "dark" ? "bg-DarkMainBg text-DarkMainText" : "bg-MainBg"
        } md:hidden top-0 left-0 max-w-full z-10 flex flex-col border-b border-[#ebe7e7]`}
      >
        <div
          className={`w-full relative flex items-center justify-between py-[20px] max-md:py-[14px] px-[4px] border-b border-[#ebe7e7]`}
        >
          <Popup
            toggleSwitch={sideBarOpen}
            children={<Sidebar />}
            className={`
        ${
          sideBarOpen
            ? lng === "Arabic"
              ? "right-0"
              : "left-0"
            : lng === "Arabic"
            ? "-right-full"
            : "-left-full"
        } 
      ${
        lng === "Arabic" ? "animate-slideFromRight" : "animate-slideFromLeft"
      } ${
              theme === "dark"
                ? "bg-DarkMainBg text-DarkMainText"
                : "text-MainText bg-MainBg"
            } top-0 fixed z-30 h-full w-[80%] max-vsm:w-[80%]`}
            onClose={() => setSideBarOpen(false)}
          />
          {backArrow ? (
            <div
              className={`${
                theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
              } p-[5px] rounded-full`}
            >
              <Link to={backLink}>
                {lng === "Arabic" ? (
                  <FaArrowRightLong size={15} />
                ) : (
                  <FaArrowLeftLong size={15} />
                )}
              </Link>
            </div>
          ) : (
            <>
              <div
                onClick={() => {
                  setSideBarOpen(true);
                }}
                className={`${
                  hideSidebar ? "hidden" : "p-[5px] cursor-pointer rounded-full"
                } ${
                  theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
                } `}
              >
                <FiMenu size={25} />
              </div>
              <div
                onClick={() => {
                  theme === "light" ? setTheme("dark") : setTheme("light");
                }}
                className={`${
                  showDMIcons ? "p-[5px] cursor-pointer rounded-full" : "hidden"
                } ${
                  theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
                } `}
              >
                {theme === "dark" ? (
                  <MdLightMode size={15} />
                ) : (
                  <MdDarkMode size={15} />
                )}
              </div>
            </>
          )}
          <Link onClick={() => logNavigation("/home")} to="/">
            <h1
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } ${
                lng === "Arabic" && "flex-row-reverse"
              } flex items-center font-bold text-[17px] tracking-[2px] absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]`}
            >
              {theme === "dark" ? (
                <img
                  className="w-[50px] h-[50px]  max-md:h-[35px]"
                  src={logoDM}
                  alt=""
                />
              ) : (
                <img
                  className="w-[50px] h-[50px] max-md:h-[40px]"
                  src={logo}
                  alt=""
                />
              )}
            </h1>
          </Link>

          {totalPrice && totalPrice !== 0 && !hideBasket ? (
            <button
              onClick={() => setCheckoutOpen(true)}
              className={`${
                priceLoading && "opacity-30 cursor-default pointer-events-none"
              } flex justify-center items-center h-[35px] w-fit min-w-[100px] text-[15px] max-md:text-[12px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 px-1`}
            >
              <div className={`flex items-center gap-1`}>
                {totalPrice.toFixed(2)} {currency} <SlBasket />
              </div>
            </button>
          ) : (
            <div
              onClick={() =>
                window.open("mailto:info@vividvisionsprint.com", "_self")
              }
              className={`${
                theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
              } w-[35px] h-[24px] pr-1 cursor-pointer rounded-full`}
            >
              <img className="w-[35px] h-[24px]" src={email} alt="Email Icon" />
            </div>
          )}
        </div>
        <div className="max-w-fit">
          <Promo />
        </div>
      </div>
      <div
        className={`${sticky ? "sticky" : "fixed"} ${className} ${
          theme === "dark" ? "bg-DarkMainBg text-DarkMainText" : "bg-MainBg"
        } max-md:hidden top-0 left-0 w-full z-10`}
      >
        <div className={`w-full relative border-b border-[#ebe7e7]`}>
          <Container className={`flex items-center justify-between py-[14px]`}>
            <Link onClick={() => logNavigation("/home")} to="/">
              <h1
                className={`${
                  theme === "dark" ? "text-DarkMainText" : "text-MainText"
                } ${
                  lng === "Arabic" && "flex-row-reverse"
                } flex items-center font-bold text-[17px] tracking-[2px]`}
              >
                {theme === "dark" ? (
                  <img
                    className=" h-[65px] max-md:w-[40px] max-md:h-[40px]"
                    src={logoDM}
                    alt=""
                  />
                ) : (
                  <img
                    className=" h-[65px] max-md:w-[40px] max-md:h-[40px]"
                    src={logo}
                    alt=""
                  />
                )}
              </h1>
            </Link>
            <div
              className={`${
                hideLapNavItems && "hidden"
              } flex items-center gap-4`}
            >
              <NavLink
                onClick={() => logNavigation("/home")}
                to={`/`}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-PrimaryColor cursor-default"
                      : theme === "dark"
                      ? "text-DarkMainText hover:text-PrimaryColor"
                      : "text-MainText hover:text-PrimaryColor"
                  } duration-300 font-bold`
                }
              >
                {t("Home")}
              </NavLink>
              {!userToken && (
                <div
                  onClick={() => {
                    setLoginOpen(true);
                  }}
                  className={`${
                    theme === "dark"
                      ? "text-DarkMainText hover:text-PrimaryColor"
                      : "text-MainText hover:text-PrimaryColor"
                  } duration-300 cursor-pointer font-bold`}
                >
                  {t("Login")}
                </div>
              )}
              <NavLink
                onClick={() => logNavigation("/edit-photos")}
                to={"/edit-photos"}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-PrimaryColor cursor-default"
                      : theme === "dark"
                      ? "text-DarkMainText hover:text-PrimaryColor"
                      : "text-MainText hover:text-PrimaryColor"
                  } duration-300 font-bold`
                }
              >
                {totalPhotos && totalPhotos > 0
                  ? `${t("Continue Design")} `
                  : t("Start Design")}
              </NavLink>
              <NavLink
                onClick={() => logNavigation("/art-home")}
                to={"/art-home"}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-PrimaryColor cursor-default"
                      : theme === "dark"
                      ? "text-DarkMainText hover:text-PrimaryColor"
                      : "text-MainText hover:text-PrimaryColor"
                  } duration-300 font-bold`
                }
              >
                {t("Art Gallery")}
              </NavLink>
              {/* {!userToken && (
                <Link
                  onClick={() => {
                    setInputPromoCodeOpen(true);
                  }}
                  className={`${
                    theme === "dark"
                      ? "text-DarkMainText hover:text-PrimaryColor"
                      : "text-MainText hover:text-PrimaryColor"
                  } duration-300 font-bold`}
                >
                  {t("Discount Codes")}
                </Link>
              )} */}
              <div
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText hover:text-PrimaryColor"
                    : "text-MainText hover:text-PrimaryColor"
                } duration-300 cursor-pointer font-bold`}
                onClick={() => {
                  theme === "light" ? setTheme("dark") : setTheme("light");
                }}
              >
                {theme === "dark" ? (
                  <MdLightMode size={20} />
                ) : (
                  <MdDarkMode size={20} />
                )}
              </div>
              {userToken && (
                <div className="relative">
                  <div
                    onClick={() => setProfileListOpen(true)}
                    className="flex items-center justify-between cursor-pointer font-bold rounded-[12px] text-[#777] bg-[#F7F7F7] hover:bg-[#EDEDED] duration-200 py-[7px] px-[13px] h-[36.5px]"
                  >
                    <FaUser />
                    <IoIosArrowDown />
                  </div>
                  {profileListOpen && <ProfileList />}
                </div>
              )}
              <img
                onClick={() => setLanguageOpen(true)}
                className="w-[28px] h-[20px] rounded-sm cursor-pointer"
                src={
                  lng === "Arabic"
                    ? saudiArabiaFlag
                    : lng === "English"
                    ? americaFlag
                    : germanyFlag
                }
                alt=""
              />

              {totalPrice && totalPrice !== 0 && !hideBasket ? (
                <button
                  onClick={() => setCheckoutOpen(true)}
                  className={`${
                    priceLoading &&
                    "opacity-30 cursor-default pointer-events-none"
                  } flex justify-center items-center h-[35px] w-fit min-w-[100px] text-[15px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300 px-1`}
                >
                  <div className={`flex items-center gap-1`}>
                    {totalPrice.toFixed(2)} {currency} <SlBasket />
                  </div>
                </button>
              ) : (
                <div
                  onClick={() =>
                    window.open("mailto:info@vividvisionsprint.com", "_self")
                  }
                  className={`${
                    theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
                  } w-[31px] h-[22px] cursor-pointer rounded-full`}
                >
                  <img className="w-[31px] h-[22px] " src={email} alt="" />
                </div>
              )}
            </div>
          </Container>
        </div>
        <div className={`bg-PrimaryColor max-w-full`}>
          <Promo />
        </div>
      </div>
      <Popup
        toggleSwitch={checkoutOpen}
        children={<Checkout />}
        className={`${
          checkoutOpen
            ? lng === "Arabic"
              ? "left-0"
              : "right-0"
            : lng === "Arabic"
            ? "-left-full"
            : "-right-full"
        } ${
          lng === "Arabic" ? "animate-slideFromLeft" : "animate-slideFromRight"
        } ${
          theme === "dark"
            ? "bg-DarkMainBg text-DarkMainText"
            : "text-MainText bg-MainBg"
        } top-0 fixed z-30 h-full overflow-y-auto overflow-x-hidden w-[420px] p-[25px] max-sm:w-[80%] checkoutBoxShadow`}
        overLayClassName={`z-20`}
        onClose={() => setCheckoutOpen(false)}
      />
      <LogInWithCode />
      {/* <PromoCode /> */}
      <InputPromoCode />
      <FAQs />
      <SelectLanguage />
    </>
  );
}

export default Navbar;
