import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastSuccess = (Msg) => {
  toast.success(Msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
  });
};

export const toastError = (err) => {
  toast.error(err, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
  });
};

export const toastLoading = (Msg) => {
  const toastId = toast.loading(Msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
  });
  return toastId; // Return the toast ID
};
