import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchActivitiesByUserId } from "../../Api/Dashboard";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "../../Context/ThemeContext";
import { PhotoContext } from "../../Context/PhotoContext";
import Loading from "../../Shared/Components/Loading";

function TractionById() {
  const { theme } = useTheme();
  const { id } = useParams();
  const { loading, setLoading } = useContext(PhotoContext);
  const [activities, setActivities] = useState([]);

  const getActivitiesData = async () => {
    try {
      setLoading(true);
      const response = await fetchActivitiesByUserId(id);
      console.log(response);
      setActivities(response.data);
    } catch (err) {
      console.error(`get activities data by user with id : ${id}`, err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getActivitiesData();
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <div>
      <h1 className="text-2xl font-semibold">Tracktions Details</h1>
      <div className="flex flex-col gap-3 mt-4">
        {loading ? (
          Array.from({ length: 2 }).map((_, outInex) => (
            <tr key={outInex}>
              {Array.from({ length: 2 }).map((_, index) => (
                <td key={index}>
                  <Skeleton width={"100%"} height={35} />
                </td>
              ))}
            </tr>
          ))
        ) : activities && activities.length > 0 ? (
          activities.map((item, index) => (
            <div key={item.id} className="flex flex-col gap-2">
              <div
                className={`${
                  theme === "dark"
                    ? "bg-DarkBoxBg text-DarkMainText shadow-[#888683]"
                    : "bg-white"
                } shadow-md min-w-[50px] min-h-[50px] flex justify-center items-center w-fit p-2 rounded-full border`}
              >
                {index + 1}
              </div>
              <table
                className={`${
                  theme === "dark"
                    ? "bg-DarkBoxBg text-DarkMainText"
                    : "bg-white"
                } w-full table-auto`}
              >
                <thead>
                  <tr>
                    <th className="border px-4 py-2 w-[50%]">Action</th>
                    <th className="border px-4 py-2 w-[50%]">Url Page</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border text-center px-4 py-2 w-[50%]">
                      {item.action_type}
                    </td>
                    <td className="border text-center px-4 py-2 w-[50%]">
                      {item.page_url}
                    </td>
                  </tr>
                </tbody>
              </table>
              {item.action_type === "add_to_cart" && (
                <table
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg text-DarkMainText"
                      : "bg-white"
                  } w-full table-auto`}
                >
                  <thead>
                    <tr>
                      <th className="border px-4 py-2">Picture Name</th>
                      <th className="border px-4 py-2">Description</th>
                      <th className="border px-4 py-2">Photo</th>
                      <th className="border px-4 py-2">Material</th>
                      <th className="border px-4 py-2">Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border text-center px-4 py-2">
                        {item?.picture?.name}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.picture?.description}
                      </td>
                      <td className="border text-center px-4 py-2">
                        <div className="w-[150px] h-[150px] mx-auto">
                          <img
                            className="w-full h-full object-cover"
                            src={item?.picture?.url_picture[0]?.url_image}
                            alt=""
                          />
                        </div>
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.material?.name}
                      </td>
                      <td className="border text-center px-4 py-2">
                        <span className="mb-1 block">
                          {item?.size?.length_inch}x{item?.size?.width_inch}inch
                        </span>
                        <span>
                          {item?.size?.length_cm}x{item?.size?.width_cm}cm
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {item.action_type === "add_to_cart edit photo" && (
                <table
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg text-DarkMainText"
                      : "bg-white"
                  } w-full table-auto`}
                >
                  <thead>
                    <tr>
                      <th className="border px-4 py-2">Material</th>
                      <th className="border px-4 py-2">Size</th>
                      <th className="border px-4 py-2">Effect</th>
                      <th className="border px-4 py-2">Mat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border text-center px-4 py-2">
                        {item?.material?.name}
                      </td>
                      <td className="border text-center px-4 py-2">
                        <span className="mb-1 block">
                          {item?.size?.length_inch}x{item?.size?.width_inch}inch
                        </span>
                        <span>
                          {item?.size?.length_cm}x{item?.size?.width_cm}cm
                        </span>
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.effect?.name}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.mat?.name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              {item.action_type === "payment" && (
                <table
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg text-DarkMainText"
                      : "bg-white"
                  } w-full table-auto`}
                >
                  <thead>
                    <tr>
                      <th className="border px-4 py-2">Order Number</th>
                      <th className="border px-4 py-2">Status</th>
                      <th className="border px-4 py-2">
                        Minumum Delivery Date
                      </th>
                      <th className="border px-4 py-2">
                        Maximum Delivery Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border text-center px-4 py-2">
                        {item?.orders?.number}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.orders?.status}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.orders?.minimum_delivery_date}
                      </td>
                      <td className="border text-center px-4 py-2">
                        {item?.orders?.max_delivery_date}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          ))
        ) : (
          <div>No Data</div>
        )}
      </div>
    </div>
  );
}

export default TractionById;
