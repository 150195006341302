import React, { useContext } from "react";
import EffectBoxItem from "./EffectBoxItem";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { updatePhotoInCart } from "../../Api/Editphotos";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function EffectList({ handleOpenPopup }) {
  const { id } = useParams();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    setUpdatePhotoArray,
    selectedEffect,
    setSelectedEffect,
    effects,
    effectsLoading,
  } = useContext(PhotoContext);

  const handleEffectClick = async (effectId) => {
    // Update the selected effect
    setSelectedEffect(effectId);

    const requestBody = {
      effect_id: effectId,
    };

    try {
      const response = await updatePhotoInCart(
        id ? `updateCartItem?id=${id}` : `updateCartItem`,
        requestBody,
        id
      );
      // console.log("Success:", response.data);
      setUpdatePhotoArray((prev) => prev + 1);
    } catch (error) {
      console.error("Update photo in cart error:", error);
    }
  };

  return (
    <div
      className={`${theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
        }
      max-w-[530px] rounded-[10px] w-full min-w-[260px] vsm:min-w-[388px] overflow-y-auto flex flex-col max-h-full`}
    >
      <div className="flex justify-between items-center m-[20px]">
        <h1
          className={`${theme === "dark" ? "text-DarkMainText/70" : "text-SecondText"
            }
          font-[600] text-[16px] leading-[16px]`}
        >
          {t("Choose Effect")}
        </h1>
        <div
          onClick={() => handleOpenPopup("effect")}
          className="text-PrimaryColor cursor-pointer p-[1em] -m-[1em]"
        >
          {t("Completed")}
        </div>
      </div>
      <div className="flex px-[20px] gap-[12px] overflow-hidden w-full sizeSwiper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={20}
          slidesPerView={"auto"}
          navigation
        >
          {effectsLoading
            ? Array.from({ length: 5 }).map((_, index) => (
              <SwiperSlide
                key={index}
                className="min-w-[130px] max-w-[130px] min-h-[150px] pb-5"
              >
                <div className="w-full h-full">
                  <Skeleton width={`100%`} className="h-full" />
                </div>
              </SwiperSlide>
            ))
            : effects.map((effect) => (
              <SwiperSlide
                key={effect.id}
                className="min-h-[150px] max-h-[150px] max-w-[130px]"
              >
                <EffectBoxItem
                  key={effect.id}
                  onClick={() => handleEffectClick(effect.id)}
                  title={effect.name}
                  isActive={selectedEffect === effect.id}
                  image={effect.url_image}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}

export default EffectList;
