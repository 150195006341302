import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAccessTokenByUserId } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import { PhotoContext } from "../../Context/PhotoContext";
import Skeleton from "react-loading-skeleton";

function AccessTokenByUserId() {
  const { theme } = useTheme();

  const { id } = useParams();
  const [data, setData] = useState("");

  const { loading, setLoading } = useContext(PhotoContext);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetchAccessTokenByUserId(id);
      setData(response.data.data[0].login_log);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <h1 className="text-2xl font-semibold">Details</h1>
      <div className="flex flex-col gap-3 mt-4">
        <div>
          <table
            className={`${
              theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
            } w-full table-auto`}
          >
            <thead>
              <tr>
                <th className="border px-4 py-2">Id</th>
                <th className="border px-4 py-2">Ip Address</th>
                <th className="border px-4 py-2">Request Time</th>
                <th className="border px-4 py-2">Country</th>
                <th className="border px-4 py-2">Region</th>
                <th className="border px-4 py-2">City</th>
                <th className="border px-4 py-2">Count</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 20 }).map((_, outInex) => (
                  <tr key={outInex}>
                    {Array.from({ length: 7 }).map((_, index) => (
                      <td key={index}>
                        <Skeleton width={"100%"} height={35} />
                      </td>
                    ))}
                  </tr>
                ))
              ) : data && data.length > 0 ? (
                data.map((user, index) => (
                  <tr key={index} className="">
                    <td className="border text-center px-4 py-2">{user.id}</td>
                    <td className="border text-center px-4 py-2">
                      {user.ip_address}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.request_time}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.countryName}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.regionName}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.cityName}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {user.count}
                    </td>
                  </tr>
                ))
              ) : (
                <div>No Data</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AccessTokenByUserId;
