import React from "react";

function Promo() {
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const ads = JSON.parse(localStorage.getItem("Ads"));

  return (
    <>
      <div className="max-md:hidden w-full overflow-hidden bg-PrimaryColor text-white font-semibold py-3 min-h-[53.44px]">
        <span
          className={`${
            lng === "Arabic" ? "marqueeArabic" : "marquee"
          } max-w-fit`}
        >
          {ads && lng === "English"
            ? ads[0]?.description_en
            : lng === "Deutsch"
            ? ads[0]?.description_gr
            : null}
        </span>
      </div>
      <div className="md:hidden w-full overflow-hidden bg-PrimaryColor text-white font-semibold py-1 min-h-[37.44px]">
        <span
          className={`${
            lng === "Arabic" ? "marqueeMobArabic" : "marqueeMob"
          } max-w-fit`}
        >
          {ads && lng === "English"
            ? ads[0]?.description_en
            : lng === "Deutsch"
            ? ads[0]?.description_gr
            : null}
        </span>
      </div>
    </>
  );
}

export default Promo;
