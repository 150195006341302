import React from "react";
import FAQsItem from "./FAQsItem";
import { Link } from "react-router-dom";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";

function FAQsList() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  return (
    <div className="pt-[25px] pr-[30px] pl-[22px] overflow-y-auto md:w-[530px] md:max-h-[500px] md:px-[44px]">
      <FAQsItem
        title={t("What is Vivid Visions?")}
        content={t(
          "Vivid Visions offers a hassle-free solution for turning your digital images into beautiful photo tiles. Choose from a variety of designs and styles to match your decor, and enjoy the flexibility of adhesive backing, which allows you to rearrange your tiles without damaging the walls."
        )}
      />
      <FAQsItem
        title={t("How to Make a Purchase?")}
        content={t(
          "Upload your images, pick your preferred style, and proceed to checkout. Vivid Visions provides a user-friendly process for designing and ordering photo tiles."
        )}
      />
      <FAQsItem
        title={`${t("Details About the Tiles")}:`}
        content={t(
          "Vivid Visions offers various sizes and frame options, including black, white, wood-toned, and frameless, as well as canvas options. You can also customize with mat shapes, colors, and collage effects."
        )}
      />
      <FAQsItem
        title={`${t("Promotional Codes")}:`}
        content={t(
          "Discounts vary depending on your selection, and you can see your savings during checkout. Subscribe to the newsletter for exclusive deals."
        )}
      />
      <FAQsItem
        title={`${t("Making Changes to Your Order")}:`}
        content={t(
          `You can edit or update your order if it hasn't been processed yet. Simply go to \"My Orders\" on the app or website, and make changes to the delivery address, style, or photos.`
        )}
      />
      <FAQsItem
        title={`${t("What to Do After Receiving Your Vivid Visions")}:`}
        content={t(
          `Instructions for applying the adhesive photo tiles to your walls, whether using sticky strips or magnetic adhesives.`
        )}
      />
      <FAQsItem
        title={`${t("Not Satisfied?")}`}
        content={t(
          `Vivid Visions offers a "Happiness Guarantee." If you are not satisfied with your product, you can contact them for support.`
        )}
      />
      <FAQsItem
        title={`${t("Shipping")}`}
        content={t(
          `Worldwide shipping is available, and sometimes there may be a small fee for smaller orders. Expedited shipping is offered in some countries, and there are no unexpected charges for Canadian customers.`
        )}
      />
      <FAQsItem
        title={`${t("Order Tracking")}`}
        content={t(
          `You will receive email notifications with updates on your order’s progress, including tracking information.`
        )}
      />
    </div>
  );
}

export default FAQsList;
