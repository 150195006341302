import React from "react";
import spinner from "../../assets/imgs/Spinner.svg";

function ProcessSpinner() {
  return (
    <div className="loadingio-spinner-spinner-qeygjx73zrn">
      <div className="ldio-la7x5mteur">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default ProcessSpinner;
