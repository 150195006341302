import React, { useEffect, useRef } from "react";
import { useTheme } from "../../../../Context/ThemeContext";

function StickyFrame() {
  const { theme } = useTheme();
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const videoRef = useRef();
  const ads = JSON.parse(localStorage.getItem("Ads"));

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const playPromise = videoElement.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Playback started successfully
          })
          .catch((error) => {
            // Handle the error, e.g., log it or show a message to the user
            console.error("Video playback failed:", error);
          });
      }
    }
  }, []);

  return (
    ads && (
      <div
        className={`${
          theme === "dark" ? "text-DarkMainText" : ""
        } rounded-[6px] bg-PrimaryColor flex items-center justify-between gap-5 p-9 max-md:flex-col-reverse max-md:gap-0`}
      >
        {/* <div className="basis-[40%] flex items-center md:max-lg:basis-[50%]">
        <video
          ref={videoRef}
          loop
          muted
          playsInline
          src="https://backend.tecrek.com/public/frontend/home/Opening_A_Christmas_Present_fhd.mp4"
        ></video>
      </div> */}
        <div className="mx-auto flex flex-col gap-3 py-3 text-center">
          <h1 className="font-bold text-4xl max-md:text-[30px]">
            {lng === "Deutsch" ? ads[1].title_gr : ads[1].title_en}
          </h1>
          <p className="text-lg font-semibold">
            {lng === "Deutsch" ? ads[1].description_gr : ads[1].description_en}
          </p>
        </div>
      </div>
    )
  );
}

export default StickyFrame;
