
// Function to ensure Pinterest tracking script is loaded
export const initializePinterestTag = () => {
    if (!window.pintrk) {
      window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments));
      };
      const script = document.createElement('script');
      script.src = 'https://s.pinimg.com/ct/core.js';
      script.async = true;
      document.body.appendChild(script);
    }
  };
  
  // Function to trigger an event
  export const triggerPinterestEvent = (eventType, eventData) => {
    initializePinterestTag(); // Ensure the Pinterest tag script is initialized
    setTimeout(() => {
      if (window.pintrk && eventType && eventData) {
        window.pintrk('track', eventType, eventData);
      }
    }, 500); // A slight delay to ensure the script is loaded
  };
  