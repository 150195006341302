import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import PopupProvider from "./Context/PopupContext";
import { PhotoProvider } from "./Context/PhotoContext";
import "./translations/i18n";
import { ThemeProvider } from "./Context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <PopupProvider>
    <ThemeProvider>
      <PhotoProvider>
        <ToastContainer />
        <App />
      </PhotoProvider>
    </ThemeProvider>
  </PopupProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
