import React, { useContext, useState } from "react";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import Popup from "../../Shared/Components/Popup";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { photoRequest } from "../../Api/Artphotos";

function PhotoRequest({ id }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { theme } = useTheme();
  const { photoRequestOpen, setPhotoRequestOpen } = useContext(PopupContext);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("picture_id", id);
    formData.append("email", email);
    try {
      setLoading(true);
      const response = await photoRequest(formData);
      // console.log(response);
      if (response.success === "true") setMessage(response.data);
    } catch (err) {
      console.error("photo request err : ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup
      toggleSwitch={photoRequestOpen}
      children={
        <div
          className={`${theme === "dark"
              ? "bg-DarkMainBg text-DarkMainText"
              : "text-MainText bg-MainBg"
            } rounded-[20px] max-md:rounded-none p-[36px] w-[402px] max-md:w-full m-auto overflow-hidden`}
        >
          {message ? (
            <div className="flex flex-col gap-4">
              <h1
                className={`${theme === "dark" && "text-DarkMainText"
                  } text-center font-[700] text-[24px] max-md:text-[20px] leading-[28px]`}
              >
                {message}
              </h1>
              <button
                onClick={() => {
                  setPhotoRequestOpen(false);
                  setMessage("");
                  setEmail("");
                }}
                className={`flex justify-center items-center cursor-pointer h-[46px] w-full min-w-[170px] text-[20px] max-md:text-[16px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300`}
                type="submit"
              >
                {t("Done")}
              </button>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <h1
                className={`${theme === "dark" && "text-DarkMainText"
                  } text-center font-[700] text-[24px] max-md:text-[20px] leading-[28px]`}
              >
                {t("Input your email")}
              </h1>
              <form onSubmit={submitHandler} className="flex flex-col gap-4">
                <input
                  className={`${theme === "dark"
                      ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                      : "text-SecondText border-[#f2e9e9] hover:border-[#b5a7ac66] focus:border-SecondText"
                    } cursor-pointer w-full duration-300 h-[46px] rounded-[8px] text-[16px] font-[500] border-2 outline-none py-[21px] px-[19px] focus:ring-0`}
                  type="text"
                  placeholder={t("Your email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  className={`${loading
                      ? "opacity-30 cursor-default pointer-events-none"
                      : ""
                    } flex justify-center items-center h-[46px] w-full min-w-[170px] text-[20px] max-md:text-[16px] text-[#fff] font-[700] outline-none rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor duration-300`}
                  type="submit"
                >
                  {loading ? (
                    <ProcessSpinner />
                  ) : (
                    <div className="w-full ">{t("Request")}</div>
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      }
      overLayClassName="z-40"
      className={` ${theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
        } z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed rounded-[20px] max-md:rounded-none max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 max-md:m-0 max-md:h-screen max-md:w-full max-md:overflow-y-hidden`}
      onClose={() => setPhotoRequestOpen(false)}
    />
  );
}

export default PhotoRequest;
