import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { toastError } from "../Shared/Components/ToastNotification/Toast";
import { useTranslation } from "react-i18next";

export const PopupContext = createContext();

function PopupProvider({ children }) {
  const { t } = useTranslation();
  const [privacySettingsOpen, setPrivacySettingsOpen] = useState(true);
  const [uploadSourceOpen, setUploadSourceOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [codeOpen, setCodeOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [profileListOpen, setProfileListOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [promoCodeOpen, setPromoCodeOpen] = useState(false);
  const [inputPromoCodeOpen, setInputPromoCodeOpen] = useState(false);
  const [FAQsOpen, setFAQsOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [chooseWayPayOpen, setChooseWayPayOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [creditCardOpen, setCreditCardOpen] = useState(false);
  const [materialSelectOpen, setMaterialSelectOpen] = useState(false);
  const [frameSelectOpen, setFrameSelectOpen] = useState(false);
  const [sizeSelectOpen, setSizeSelectOpen] = useState(false);
  const [effectSelectOpen, setEffectSelectOpen] = useState(false);
  const [matSelectOpen, setMatSelectOpen] = useState(false);
  const [sortListOpen, setSortListOpen] = useState(false);
  const [cropOpen, setCropOpen] = useState(false);
  const [addPictureToCartTypeOpen, setAddPictureToCartTypeOpen] =
    useState(false);
  const [paymentFormOpen, setPaymentFormOpen] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [photoRequestOpen, setPhotoRequestOpen] = useState(false);

  const loginFirst = () => {
    // toastError(t("Please sign in to your account to continue"));
    setLoginOpen(true);
  };
  // dashboard
  const [dashboardSideBarOpen, setDashboardSideBarOpen] = useState(true);
  const [makeDecisionOpen, setMakeDecisionOpen] = useState(false);
  const [addNewItemOpen, setAddNewItemOpen] = useState(false);
  const [updateItemOpen, setUpdateItemOpen] = useState(false);
  const [addNewCategoryOpen, setAddNewCategoryOpen] = useState(false);
  const [updateCategoryOpen, setUpdateCategoryOpen] = useState(false);
  const [updateUserTypeOpen, setUpdateUserTypeOpen] = useState(false);
  const [addNewSizeOpen, setAddNewSizeOpen] = useState(false);
  const [updateSizeOpen, setUpdateSizeOpen] = useState(false);
  const [orderFilterOpen, setOrderFilterOpen] = useState(false);
  const [updateOrderStatusOpen, setUpdateOrderStatusOpen] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const contextValue = {
    uploadSourceOpen,
    setUploadSourceOpen,
    loginOpen,
    setLoginOpen,
    codeOpen,
    setCodeOpen,
    privacySettingsOpen,
    setPrivacySettingsOpen,
    sideBarOpen,
    setSideBarOpen,
    profileListOpen,
    setProfileListOpen,
    languageOpen,
    setLanguageOpen,
    promoCodeOpen,
    setPromoCodeOpen,
    inputPromoCodeOpen,
    setInputPromoCodeOpen,
    FAQsOpen,
    setFAQsOpen,
    checkoutOpen,
    setCheckoutOpen,
    chooseWayPayOpen,
    setChooseWayPayOpen,
    addressOpen,
    setAddressOpen,
    creditCardOpen,
    setCreditCardOpen,
    materialSelectOpen,
    setMaterialSelectOpen,
    frameSelectOpen,
    setFrameSelectOpen,
    sizeSelectOpen,
    setSizeSelectOpen,
    effectSelectOpen,
    setEffectSelectOpen,
    matSelectOpen,
    setMatSelectOpen,
    cropOpen,
    setCropOpen,
    sortListOpen,
    setSortListOpen,
    cropOpen,
    setCropOpen,
    addPictureToCartTypeOpen,
    setAddPictureToCartTypeOpen,
    paymentFormOpen,
    setPaymentFormOpen,
    showResendButton,
    setShowResendButton,
    photoRequestOpen,
    setPhotoRequestOpen,
    loginFirst,
    dashboardSideBarOpen,
    setDashboardSideBarOpen,
    makeDecisionOpen,
    setMakeDecisionOpen,
    addNewItemOpen,
    setAddNewItemOpen,
    updateItemOpen,
    setUpdateItemOpen,
    addNewCategoryOpen,
    setAddNewCategoryOpen,
    updateCategoryOpen,
    setUpdateCategoryOpen,
    updateUserTypeOpen,
    setUpdateUserTypeOpen,
    addNewSizeOpen,
    setAddNewSizeOpen,
    updateSizeOpen,
    setUpdateSizeOpen,
    orderFilterOpen,
    setOrderFilterOpen,
    updateOrderStatusOpen,
    setUpdateOrderStatusOpen,
    startDateOpen,
    setStartDateOpen,
    endDateOpen,
    setEndDateOpen,
  };
  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
}

export default PopupProvider;
