import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";

function ProfileListItem({ onClick, link, content }) {
  const { theme } = useTheme();
  return (
    <Link
      onClick={onClick}
      to={link}
      className={`${
        theme === "dark"
          ? "text-DarkMainText hover:bg-DarkBoxBg"
          : "text-MainText hover:bg-[#bbbbbb]"
      } min-w-[200px] duration-300 px-2 py-1`}
    >
      {content}
    </Link>
  );
}

export default ProfileListItem;
