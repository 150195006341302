import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useTheme } from "../../Context/ThemeContext";
import { useLocation, useNavigate } from "react-router-dom";
import { apiClient } from "../../Api/api";
import { useTranslation } from "react-i18next";

function GoogleCallBack() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const googleCall = async () => {
      try {
        const response = await apiClient.get(
          `/api/auth/google/callback?code=${location.search}`
        );
        // console.log(response);
        Cookies.set("userToken", response.data.token, { expires: 365 });
        window.localStorage.setItem("email", response.data.user.email);
        window.localStorage.setItem("name", response.data.user.name);
        navigate("/");
      } catch (err) {
        console.error("google call err", err);
      }
    };
    googleCall();
  }, []);
  return (
    <div
      className={`${theme === "dark" ? "text-DarkMainText bg-DarkMainBg" : "text-MainText"
        } h-screen flex justify-center items-center text-2xl`}
    >
      {t("Loading")} ...
    </div>
  );
}

export default GoogleCallBack;
