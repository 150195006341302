import React from "react";

function MatBoxItem({ isActive, image, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`${
        isActive
          ? "cursor-default pointer-events-none border-PrimaryColor"
          : "cursor-pointer border-[#ebe7e7]"
      } border-[2px] w-[130px] h-full min-h-full duration-300 flex flex-col items-center rounded-[5px] overflow-hidden`}
    >
      <img className={`w-full h-full object-cover`} src={image} alt="" />
    </div>
  );
}

export default MatBoxItem;
