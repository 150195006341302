import { useState, useEffect, useContext } from "react";
import { FaUserEdit } from "react-icons/fa";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "../../Shared/Components/Popup";
import UpdateUserType from "../Components/UpdateUserType";
import { fetchUsers } from "../../Api/Dashboard";
import MakeDecision from "../../Shared/Components/MakeDecision";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../Shared/Components/CustomPagination";
import { adminToken } from "../../Api/api";
import SearchInput from "../Components/SearchInput";

const UsersView = () => {
  const { theme } = useTheme();
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
const [from, setFrom] = useState("");
const [to, setTo] = useState("");
const [total, setTotal] = useState("");
const [refreshKey, setRefreshKey] = useState(Date.now());
const [pendingUserId, setPendingUserId] = useState(null);
const { loading, setLoading } = useContext(PhotoContext);
const {
  makeDecisionOpen,
  setMakeDecisionOpen,
  updateUserTypeOpen,
  setUpdateUserTypeOpen,
} = useContext(PopupContext);

const handlePageClick = (data) => {
  const selectedPage = data.selected + 1;
  // console.log(selectedPage);
  setPageNumber(selectedPage);
};
const [query, setQuery] = useState("");
const [searchloading, setSearchLoading] = useState(false);
const search = async (e) => {
  setQuery(e.target.value);
  query && (await fetchUsersData());
};
const fetchUsersData = async () => {
  if (adminToken) {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const usersData = await fetchUsers(pageNumber, query);
      // console.log(usersData);
      setUsers(usersData.data);
      setTotalPages(usersData.last_page);
      setItemPerPage(usersData.per_page);
      setPageNumber(usersData.current_page);
      setFrom(usersData.from);
      setTo(usersData.to);
      setTotal(usersData.total);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Ensure loading stops regardless of the fetch outcome
      query && setSearchLoading(false);
    }
  } else {
    window.location.reload();
  }
};
useEffect(() => {
  fetchUsersData();
}, [refreshKey, pageNumber, query]);
const toggleActiveStatus = (userId) => {
  setPendingUserId(userId); // Store the user ID that needs to be toggled
  setMakeDecisionOpen(true); // Open the decision popup
};

return (
  <div>
    <h1 className="text-2xl font-semibold">Users</h1>
    <div className="flex flex-col gap-3 mt-4">
      <SearchInput
        query={query}
        search={(e) => search(e)}
        loading={searchloading}
        placeHolder={"Search"}
      />
      <div>
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Id</th>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Type</th>
              <th className="border px-4 py-2">Active Status</th>
              <th className="border px-4 py-2">Created At</th>
              <th className="border px-4 py-2">Country</th>
              <th className="border px-4 py-2">Region</th>
              <th className="border px-4 py-2">City</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 20 }).map((_, outInex) => (
                <tr key={outInex}>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <td key={index}>
                      <Skeleton width={"100%"} height={35} />
                    </td>
                  ))}
                </tr>
              ))
            ) : users && users.length > 0 ? (
              users.map((user, index) => (
                <tr key={index} className="">
                  <td className="border text-center px-4 py-2">{user.id}</td>
                  <td className="border text-center px-4 py-2">{user.name}</td>
                  <td className="border text-center px-4 py-2">{user.email}</td>
                  <td className="border text-center px-4 py-2">
                    {user.user_type.type}
                  </td>
                  <td
                    className={`${
                      user.isActive === 1
                        ? theme === "dark"
                          ? "text-green-300"
                          : "text-green-800"
                        : theme === "dark"
                        ? "text-red-300"
                        : "text-red-700"
                    } border font-semibold text-center px-4 py-2`}
                  >
                    {user.isActive === 1 ? "Active" : "Not Active"}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {user.created_at_formatted}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {user?.location?.country}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {user?.location?.region}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {user?.location?.city}
                  </td>
                  <td className="flex justify-center items-center border text-center px-4 py-2">
                    <button
                      onClick={() => {
                        setSelectedUserId(user.id);
                        setSelectedUserName(user.name);
                        setSelectedUserEmail(user.email);
                        setSelectedUserType(user.user_type.type);
                        setUpdateUserTypeOpen(true);
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 min-h-[40px] flex justify-center items-center duration-300 rounded"
                    >
                      <FaUserEdit />
                    </button>
                    <button
                      onClick={() => toggleActiveStatus(user.id)}
                      className={`${
                        user.isActive === 1
                          ? "bg-red-500 hover:bg-red-700"
                          : "bg-green-500 hover:bg-green-700"
                      } text-white font-bold px-4 min-w-[115px] min-h-[40px] flex justify-center items-center duration-300 rounded ml-4`}
                    >
                      {user.isActive === 1 ? "Deactivate" : "Activate"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div>No Data</div>
            )}
          </tbody>
        </table>
        {updateUserTypeOpen && (
          <Popup
            toggleSwitch={updateUserTypeOpen}
            children={
              <UpdateUserType
                id={selectedUserId}
                userName={selectedUserName}
                userEmail={selectedUserEmail}
                currentType={selectedUserType}
                refreshUsers={() => setRefreshKey(Date.now())}
              />
            }
            overLayClassName="z-40"
            className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
            onClose={() => setUpdateUserTypeOpen(false)}
          />
        )}
        <Popup
          toggleSwitch={makeDecisionOpen}
          children={
            <MakeDecision
              dashboard={true}
              action={pendingUserId ? "deactivate" : "activate"}
              type="user"
              userId={pendingUserId}
              refreshUsers={() => setRefreshKey(Date.now())}
              setUsers={setUsers}
            />
          }
          overLayClassName="z-40"
          className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setUpdateUserTypeOpen(false)}
        />
      </div>
      <CustomPagination
        totalPages={totalPages}
        currentPage={pageNumber}
        itemPerPage={itemPerPage}
        handlePageClick={handlePageClick}
        from={from}
        to={to}
        total={total}
      />
    </div>
  </div>
);
};

export default UsersView;
