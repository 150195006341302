import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/Logo/logo.png";
import logoDM from "../../assets/imgs/Logo/darkModeLogo.png";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { useTranslation } from "react-i18next";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import { useTheme } from "../../Context/ThemeContext";
import americaFlag from "../../assets/imgs/americaFlag.png";
import germanyFlag from "../../assets/imgs/germanyFlag.png";
import saudiArabiaFlag from "../../assets/imgs/saudiArabiaFlag.png";
import SelectLanguage from "./SelectLanguage";
import facebookLogo from "../../assets/imgs/SocialMediaLogo/fbk.png";
import instagramLogo from "../../assets/imgs/SocialMediaLogo/ig.png";
import pinterestLogo from "../../assets/imgs/SocialMediaLogo/pt.png";
import tictokLogo from "../../assets/imgs/SocialMediaLogo/tt.png";
import emailLogo from "../../assets/imgs/SocialMediaLogo/em.webp";


function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { theme } = useTheme();
  const { selectedLanguage } = useContext(PhotoContext);
  const { setLanguageOpen, setFAQsOpen, setPromoCodeOpen } =
    useContext(PopupContext);
  const { t } = useTranslation();
  const lng = localStorage.getItem("vividVisionsLang") || "English";

  return (
    <>
      {" "}
      <div className={`${theme === "dark" && "text-DarkMainText"} `}>
        <div className="grid grid-cols-4 text-left max-md:grid-cols-1">
          <Link
            to="/"
            className={`${
              lng === "Arabic" && "flex-row-reverse"
            } flex items-center font-bold text-[17px] tracking-[2px] my-5 w-fit`}
          >
            {theme === "dark" ? (
              <img className=" h-[50px]" src={logoDM} alt="" />
            ) : (
              <img className=" h-[50px]" src={logo} alt="" />
            )}
          </Link>
          <div className="flex flex-col">
            <h1 className="text-[16px] py-3 font-bold max-md:hidden w-fit">
              {t("About")}
            </h1>
            <Link
              to={"/art-home"}
              className="md:block md:w-fit md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              {t("Art Gallery")}
            </Link>
            <Link
              to={"/reviews"}
              className="md:block md:w-fit md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              {t("Reviews")}
            </Link>
            <Link
              onClick={() => setFAQsOpen(true)}
              className="md:block md:w-fit md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              {t("FAQs")}
            </Link>
          </div>
          <BreakDiv className="my-[10px] md:hidden" />
          <div className="flex flex-col">
            <h1 className=" text-[16px] py-3 font-bold max-md:hidden w-fit">
              {t("Follow Us")}
            </h1>
            <Link
              to="https://www.pinterest.com/vividvisionsprint/"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              <img className="w-[20px] h-[20px]" src={pinterestLogo} alt="" />
              {t("Pinterest")}
            </Link>
            <Link
              to="https://www.instagram.com/vividvisionsprint?igsh=MXEzbDVuenA2eXV1NA=="
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              <img className="w-[20px] h-[20px]" src={instagramLogo} alt="" />
              {t("Instagram")}
            </Link>
            <Link
              to="https://www.facebook.com/share/E3zwfbST2zqwokUh/?mibextid=LQQJ4d"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              <img className="w-[20px] h-[20px]" src={facebookLogo} alt="" />
              {t("Facebook")}
            </Link>
            <Link
              to="https://www.tiktok.com/@vividvisionsprint?_t=8qDBs4yZ4mz&_r=1"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              <img className="w-[20px] h-[20px]" src={tictokLogo} alt="" />
              {t("Tiktok")}
            </Link>
          </div>
          <div className="flex flex-col">
            <h1 className="text-[16px] py-3 font-bold max-md:hidden w-fit">
              {t("Contact Us")}
            </h1>
            <Link
              to="mailto:info@vividvisionsprint.com"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:duration-300 md:text-[16px] md:py-3 max-md:leading-[42px]"
            >
              <img className="w-[25px] h-[17px]" src={emailLogo} alt="" />
              info@vividvisionsprint.com
            </Link>
          </div>
        </div>
        <BreakDiv className="my-[10px] md:hidden" />
        <div className="flex items-center justify-between pb-4 max-lg:grid max-lg:grid-cols-2 max-md:grid-cols-1 max-md:gap-5">
          <div className="flex items-center md:gap-3 max-md:flex-col max-md:items-start">
            <Link
              to="/terms-of-service"
              className={`${
                theme === "dark" && "text-DarkMainText/70"
              } text-[12px] leading-[33px]`}
            >
              {t("Terms of Service")}
            </Link>
          </div>
          <div className="text-[#958289] text-[14px] leading-[18px]">
            {t(
              `© ${t("All rights reserved")} 2023-${currentYear} Vivid Visions`
            )}
          </div>
          <div
            onClick={() => setLanguageOpen(true)}
            className={`${
              theme === "dark"
                ? "text-DarkMainText bg-[#535353] hover:bg-[#414141]"
                : "text-MainText bg-white  hover:bg-[#d1d1d1] shadow-md"
            } duration-300 flex items-center justify-between mt-4 mb-6 py-2 px-4 cursor-pointer rounded-xl hover:bg-[#EDEDED] w-[150px]`}
          >
            <h1>{selectedLanguage}</h1>
            <img
              onClick={() => setLanguageOpen(true)}
              className="w-[28px] h-[20px] rounded-sm cursor-pointer"
              src={
                selectedLanguage === "Arabic"
                  ? saudiArabiaFlag
                  : selectedLanguage === "English"
                  ? americaFlag
                  : germanyFlag
              }
              alt=""
            />
          </div>
        </div>
      </div>
      <SelectLanguage />
    </>
  );
}

export default Footer;
