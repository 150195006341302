import React, { useContext, useEffect, useState } from "react";
import { fetchUsersActivities } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import { PhotoContext } from "../../Context/PhotoContext";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../Shared/Components/CustomPagination";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import SearchInput from "../Components/SearchInput";

function Tracktions() {
  const { theme } = useTheme();
  const [tracktions, setTracktions] = useState([]);
  const [total, setTotal] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const {
    loading,
    setLoading,
    totalPages,
    setTotalPages,
    itemPerPage,
    setItemPerPage,
    pageNumber,
    setPageNumber,
  } = useContext(PhotoContext);
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);
  const search = async (e) => {
    setQuery(e.target.value);
    query && (await getTracktionsData());
  };
  const getTracktionsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchUsersActivities(pageNumber, query);
      // console.log(response.data);
      setTracktions(response.data.data);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setPageNumber(response.data.current_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get tractions data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };
  useEffect(() => {
    getTracktionsData();
  }, [pageNumber, query]);
  return (
    <div>
      <h1 className="text-2xl font-semibold">VividVisions Tracktions</h1>
      <div className="flex flex-col gap-3 mt-4">
        <SearchInput
          query={query}
          search={(e) => search(e)}
          loading={searchloading}
          placeHolder={"Search"}
        />
        <div>
          <table
            className={`${
              theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
            } w-full table-auto`}
          >
            <thead>
              <tr>
                <th className="border px-4 py-2">Id</th>
                <th className="border px-4 py-2">Name</th>
                <th className="border px-4 py-2">Email</th>
                <th className="border px-4 py-2">Created At</th>
                <th className="border px-4 py-2">Country</th>
                <th className="border px-4 py-2">Region</th>
                <th className="border px-4 py-2">City</th>
                <th className="border px-4 py-2 relative">
                  <HiDotsVertical className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]" />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 20 }).map((_, outInex) => (
                  <tr key={outInex}>
                    {Array.from({ length: 8 }).map((_, index) => (
                      <td key={index}>
                        <Skeleton width={"100%"} height={35} />
                      </td>
                    ))}
                  </tr>
                ))
              ) : tracktions && tracktions.length > 0 ? (
                tracktions.map((item, index) => (
                  <tr key={index.id}>
                    <td className="border text-center px-4 py-2">{item.id}</td>
                    <td className="border text-center px-4 py-2">
                      {item.name}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item.email}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item.created_at_formatted}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.location?.country}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.location?.region}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.location?.city}
                    </td>
                    <td className="border text-center px-4 py-2">
                      {item?.user_activity?.length > 0 ? (
                        <Link
                          to={`/dashboard/tracktions/${item.id}`}
                          className="text-PrimaryColor font-bold cursor-pointer hover:underline duration-300"
                        >
                          view
                        </Link>
                      ) : (
                        "No Data"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div>No Data</div>
              )}
            </tbody>
          </table>
        </div>
        {tracktions.length > 0 && (
          <CustomPagination
            totalPages={totalPages}
            currentPage={pageNumber}
            itemPerPage={itemPerPage}
            handlePageClick={handlePageClick}
            from={from}
            to={to}
            total={total}
          />
        )}
      </div>
    </div>
  );
}

export default Tracktions;
